import React, { Component } from "react";
import { logout } from "../../../actions/AppActions";
import { MdReply, MdVisibility } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import Tooltip from "react-tooltip";
import { URL, URLopen } from "../../../variables";
import { GET } from "../../../Auxiliar/Requests";
import { maskDate } from "../../../Auxiliar/Masks";

export class ViewAgendamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/view",
      loading: true,
      situation: "Expirado",
      user: [],
      host: "",
      enterprise: [],
      enterprise_type: "",

      logs: [],
    };
  }

  componentDidMount() {
    this.getData(this.props.match.params.id)
  }

  async getData(id) {
    const response = await GET({ url: `auth/get_solicitation/${id}?url_back=${URL}`, blueenOpen: true })
    console.log('response', response)

    this.setState({ collect_solicitation: response.collect_solicitation, loading: false })
  }

  handleStatus() {
    if (this.state.collect_solicitation.status === "Aceito") {
      return <BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} />;
    }
    if (this.state.collect_solicitation.status === "Cancelado") {
      return <BsCircleFill className="mx-1" color="#dc3545" size={"1.25vh"} />;
    }
    if (this.state.collect_solicitation.status === "Pendente") {
      return <BsCircleFill className="mx-1" color="#ffc107" size={"1.25vh"} />;
    }
    if (this.state.collect_solicitation.status === "Finalizado") {
      return <BsCircleFill className="mx-1" color="#73B054" size={"1.25vh"} />;
    }
  }

  render() {
    return (
      <div className='row'>
        {!this.state.loading ? <div>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Agendamento - #13032023</h4> */}
                <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados agendamento:</label>
                <hr />
                <div className="row">
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-sm-12 col-form-label" >Status: <span style={{ fontWeight: 'normal' }}>{this.state.collect_solicitation.status}{this.handleStatus()}</span></label>
                  </div>
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-sm-12 col-form-label">Data: <span style={{ fontWeight: 'normal' }}>{maskDate(this.state.collect_solicitation.created_at)}</span></label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-sm-12 col-form-label" >Responsável: <span style={{ fontWeight: 'normal' }}>{this.state.collect_solicitation.responsible.name}</span></label>
                  </div>
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-sm-12 col-form-label">Aceito em: <span style={{ fontWeight: 'normal' }}> --/--/----</span></label>
                  </div>
                </div>

                <div className="table-rep-plugin">
                  <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Resíduos/Rejeitos:</label>
                    <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Peso</th>
                          <th>Volume</th>
                          <th>Peso KG</th>
                          <th>Volume LT</th>
                          <th>Financeiro</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.collect_solicitation.waste_rejects.map(item => (
                          <tr key={item.id}>
                            <td>{item.waste_reject.name}</td>
                            <td>{item.weight} {item.waste_reject.primary_unit.abbreviation}</td>
                            <td>{item.volume} {item.waste_reject.secondary_unit.abbreviation}</td>
                            <td>{item.weight_kg} Kg</td>
                            <td>{item.volume_lt} L</td>
                            <td>{item.waste_reject.financial_type}</td>
                            <td>R$: {item.balance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                  <Link to={'/agendamentos'}>
                    <button type="button" className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply />Voltar</button>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
          :
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(ViewAgendamento);
