import React from 'react'
import { MdReply } from 'react-icons/md'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const PresetButtons = ({ loadingSave, backPath, label, handleSave }) => {
  return (
    <>
      {!loadingSave && (
        <div className="row mt-3">
          <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
            <Link to={backPath}>
              <span className="btn btn-secondary btn-lg waves-effect waves-light">
                <MdReply /> Voltar
              </span>
            </Link>
          </div>
          <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
            <button
              type="submit"
              className="btn btn-success btn-lg waves-effect waves-light"
              style={{ backgorundColor: '#f2f2f2' }}
            >
              {label}
            </button>
          </div>
        </div>
      )}

      {loadingSave && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      )}
    </>
  )
}

export default PresetButtons