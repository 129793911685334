import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { GET } from '../../../Auxiliar/Requests';
import CriarCompliance from './CriarCompliance';
import { months, timeUnits } from '../../../Auxiliar/options';
import { maskMoneyV2 } from '../../../Auxiliar/Masks';

class EditarCompliance extends Component {
  constructor(props) {
    super(props);
    const { owner, id } = this.props.match.params;
    this.state = {
      owner: owner,
      ownerId: id,
      editData: '',
      loadingScreen: true,
      selectOptions: []
    };
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    const response = await GET({ url: `get_compliance/${this.state.owner}/${this.state.ownerId}` })
    if (response.error) {
      this.setState({ redirect: true })
      return
    }

    const contents = response.compliance.contents.map(item => {
      let unity = ''
      let dateLabel = ''
      let notificationLabel = ''
      // Unity labels
      if (item.type === 'Quantidade') unity = ' KG'
      if (item.type === 'Volume') unity = ' LT'
      // Notification labels
      if (item.type === 'Validade') {
        // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
        notificationLabel = item?.notifications.reduce((accumulator, notification) => {
          if (accumulator === '') return `${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
          else return `${accumulator}, ${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
        }, '')
      } else {
        notificationLabel = `${item.notification}% da capacidade máxima`
      }

      // Date labels
      if (item.type === 'Validade') {
        const [year, month, day] = item.expiration_date.split("-");
        dateLabel = `Válido até ${day}/${month}/${year}`
      }

      if (item.monthly_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${months.find(month => month.value === item.monthly_start_date).name}`
      }
      else if (item.annual_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${item.annual_start_date}`
      }

      return {
        ...item,
        unity,
        notification_label: notificationLabel,
        content_label: item.type === 'Saldo Financeiro' ? maskMoneyV2(item.content) : item.content,
        date_label: dateLabel
      }
    })

    this.setState({ editData: { ...response.compliance, contents }, loadingScreen: false, selectOptions: response.select_options })
    console.log('edit response', response)
  }

  render() {
    return (
      <>
        {!this.state.loadingScreen
          ?
          <CriarCompliance
            selectOptions={this.state.selectOptions}
            editData={this.state.editData}
            {...this.props}
          />
          :
          <div className='d-flex justify-content-center p-5'>
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        }
      </>
    )
  }
}

export default EditarCompliance;