
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Chart from "react-apexcharts";

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import LineAreaChart from "../../../AllCharts/apex/lineareachart";
import ptBr from 'apexcharts/dist/locales/pt-br.json';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';
import DashboardFilter from '../../../Auxiliar/DashboardFilter';


function generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([baseval, y]);
        baseval += 86400000;
        i++;
    }
    return series;
}
var randomizeArray = function (arg) {
    var array = arg.slice();
    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {

        randomIndex = Math.abs(Math.floor(Math.random() * currentIndex));
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

var options_ = {
    series: [
        {
            name: 'Estoque',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 60
            })
        },
        {
            name: 'Ponto de coleta',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 20
            })
        },
        {
            name: 'Solicitações',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 15
            })
        }
    ],

    chart: {
        type: 'area',
        height: 350,
        stacked: true,
        locales: [ptBr],
        defaultLocale: 'pt-br',
        events: {
            selection: function (chart, e) {
                console.log(new Date(e.xaxis.min))
            }
        },
    },
    colors: ['#008FFB', '#00E396', '#CED4DC'],
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    fill: {
        type: 'gradient',
        gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
        }
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left'
    },
    xaxis: {
        type: 'datetime',

    },
};

// data for the sparklines that appear below header area
var sparklineData = [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51, 35, 41, 35, 27, 93, 53, 61, 27, 54, 43, 19, 46];
var spark1 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline3',
        group: 'sparklines',
        type: 'area',
        height: 50,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',

    },
    yaxis: {
        min: 0
    },
    colors: ['#3ABBE2'],
    //colors: ['#5564BE'],

}

var spark2 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',
    },
    yaxis: {
        min: 0
    },
    colors: ['#81D6EB'],
    //colors: ['#5564BE'],

}

var spark3 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',
    },
    yaxis: {
        min: 0
    },
    colors: ['#FDE07E'],
    //colors: ['#5564BE'],

}

var spark4 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',

    },
    yaxis: {
        min: 0
    },
    colors: ['#F89194'],
    //colors: ['#5564BE'],

}
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            RegistrosColeta: {},
            dados_contato: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            registro_to_delete: '',
            search: '',

            lista_rr: [],
            num_ecopontos_ativos: 0,
            num_solicitacoes: 0,
            ponto_coleta: [],
            quantidade_residuos_coletados: 0,
            residuo_maior_kg: [],
            residuo_maior_valor: [],
            saldo_financeiro: 0,

            solicitacoes_coleta_aguardando: 0,
            solicitacoes_coleta_andamento: 0,
            solicitacoes_coleta_finalizado: 0,
            solicitacoes_coleta_total: 0,
            solicitacoes_coleta_total: 0,
            total_kg: 1,
            total_valor: [],
            escala: 'mes',

            loading_graph: true,

            saldos_graficos: [],
            datas_grafico: [],
            pesos_grafico: [],
            solicitacoes_grafico: [],
            pontos_coleta_grafico: [],
            options_: {
                series: [
                    {
                        name: 'Evolução em R$',
                        data: []
                    },
                    {
                        name: 'Evolução em KG',
                        data: []
                    },
                    {
                        name: 'Ponto de coleta',
                        data: []
                    },
                    {
                        name: 'Solicitações',
                        data: []
                    }
                ],
                chart: {
                    type: 'area',
                    height: 350,
                    stacked: true,
                    locales: [ptBr],
                    defaultLocale: 'pt-br',
                    events: {
                        selection: function (chart, e) {
                            console.log(new Date(e.xaxis.min))
                        }
                    },
                    zoom: {
                        enabled: false
                    }
                },
                colors: ['#008FFB', '#00E396', '#CED4DC', '#000'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.6,
                        opacityTo: 0.8,
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function (value) {
                            // console.log(value)
                            // console.log(moment.unix(value/1000).format('MM/YYYY'));
                            return moment.unix(value / 1000).format('MM/YYYY');
                        }
                    },
                    tickAmount: 5,
                    tickPlacement: 'between'
                },
                labels: [],
            },

            filter_month: new Date().getMonth() + 1,
            filter_year: new Date().getFullYear(),
            filter_rrs: [],
            filter_unity: 'peso',

            options_rr: [],
            selected_rr: '',
            rr_error: '',
        };
    }

    componentDidMount() {
        this.get_dados_dashboard(this.state.page);

        this.get_dados_grafico(this.state.escala);
        console.log(generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, { min: 10, max: 60 }));
    }


    get_dados_dashboard() {
        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }

        const residuo_rejeito_ids = this.state.filter_rrs.length > 0 ? encodeURIComponent(JSON.stringify(this.state.filter_rrs)) : ''

        const { filter_year, filter_month, filter_unity } = this.state;
        const formattedMonth = filter_month.toString().padStart(2, '0');
        const period = `${filter_year}-${formattedMonth}`;

        fetch(`${URL}api/get_dados_dashboard?residuo_rejeito_ids=${residuo_rejeito_ids}&period=${period}&unity=${filter_unity}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({
                        quantidade_residuos_coletados: resp.quantidade_residuos_coletados,
                        ponto_coleta: resp.ponto_coleta,
                        num_ecopontos_ativos: resp.num_ecopontos_ativos,
                        num_solicitacoes: resp.num_solicitacoes,
                        lista_rr: resp.lista_rr,
                        residuo_maior_kg: resp.residuo_maior_kg,
                        residuo_maior_valor: resp.residuo_maior_valor,
                        saldo_financeiro: resp.saldo_financeiro,
                        solicitacoes_coleta_aguardando: resp.solicitacoes_coleta_aguardando,
                        solicitacoes_coleta_andamento: resp.solicitacoes_coleta_andamento,
                        solicitacoes_coleta_finalizado: resp.solicitacoes_coleta_finalizado,
                        solicitacoes_coleta_total: resp.solicitacoes_coleta_total,
                        total_kg: resp.total_kg,
                        total_valor: resp.total_valor,
                        loading_screen: false,
                        options_rr: resp.residuos,
                        unity: resp.unidade,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }


    get_dados_grafico(escala) {
        if (this.state.loading_graph === false) {
            this.setState({ loading_graph: true });
        }
        fetch(`${URL}api/get_dados_grafico/${escala}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_graph: true });
                }
                else {
                    let options_ = { ...this.state.options_ };

                    // for (let i = 0; i < resp.datas_grafico.length; i++) {
                    //     pesos.push(resp.pesos_grafico[i]);
                    //     saldos.push(resp.saldos_graficos[i]);

                    // }
                    options_.series[0].data = resp.saldos_graficos;

                    options_.series[1].data = resp.pesos_grafico;
                    options_.series[2].data = resp.pontos_coleta_grafico;
                    options_.series[3].data = resp.solicitacoes_grafico;

                    options_.labels = resp.datas_grafico;
                    // series: [
                    //     {
                    //         name: 'Evolução em R$',
                    //         data: []
                    //     },
                    //     {
                    //         name: 'Evolução em KG',
                    //         data: []
                    //     },
                    //     {
                    //         name: 'Ponto de coleta',
                    //         data: []
                    //     },
                    //     {
                    //         name: 'Solicitações',
                    //         data: []
                    //     }
                    // ],

                    this.setState({
                        saldos_graficos: resp.saldos_graficos,
                        datas_grafico: resp.datas_grafico,
                        pesos_grafico: resp.pesos_grafico,
                        solicitacoes_grafico: resp.solicitacoes_grafico,
                        pontos_coleta_grafico: resp.pontos_coleta_grafico,
                        options_,
                        loading_graph: false,
                    });
                    console.log(resp.saldos_graficos)
                }
            } catch (err) {
                this.setState({ loading_graph: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ loading_graph: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }





    changeState = (args) => {
        console.log('args', args)
        this.setSate(args)
    }


    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}



                {this.state.loading_screen == false && <div className="col-12">
                    <div>
                        <h1 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Indicadores</h1>
                        <p>Destinação por período</p>
                    </div>

                    <DashboardFilter
                        filterMonth={this.state.filter_month}
                        filterYear={this.state.filter_year}
                        filterUnity={this.state.filter_unity}
                        filterRrs={this.state.filter_rrs}
                        optionsRr={this.state.options_rr}
                        selectedRr={this.state.selected_rr}
                        getData={() => this.get_dados_dashboard()}
                        setState={(args) => this.setState(args)}
                    />

                    <div className="row mb-2">
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #36B2E2, #2D98C6)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.quantidade_residuos_coletados)} {this.state.unity}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Quantidade de resíduos coletados</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark1}
                                        series={spark1.series}

                                        type="line"
                                        height="70"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #57CAE5, #3ABCDE)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>R$ {maskNumberBr(parseFloat(this.state.saldo_financeiro).toFixed(2))}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Valor financeiro</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark2}
                                        series={spark2.series}

                                        type="line"
                                        height="70"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #FAC924, #F6B000)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.num_ecopontos_ativos)}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Número de ecopontos ativos</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark3}
                                        series={spark3.series}

                                        type="area"
                                        height="80"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #F7777B, #F5424D)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.num_solicitacoes)}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Número de solicitações de coleta</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark4}
                                        series={spark4.series}

                                        type="bar"
                                        height="80"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="card ">
                        <div className="card-body" id="card" style={{ paddingBottom: 0 }}>



                            {/* <hr /> */}
                            <div>

                                <div className="row mb-2">
                                    <div className="col-12 col-md-12">
                                        <h3>Tráfego</h3>

                                    </div>
                                    {this.state.loading_graph == false && <div className="col-12 col-md-12 custom_scrollbar">
                                        {/* <LineAreaChart /> */}
                                        <Chart
                                            options={this.state.options_}
                                            series={this.state.options_.series}

                                            type="area"
                                            height="400"
                                        // width="500"
                                        />
                                    </div>}
                                    {this.state.loading_graph == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div>}



                                </div>


                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>



                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.delete_entrada_coleta(this.state.registro_to_delete); }}
                                onCancel={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao deletar este registro de entrada ele não estará mais disponível no sistema

                            </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({ show_erro: false });
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgErro}

                            </SweetAlert>

                        </div>
                        {this.state.loading_graph == false && <div className="row " >
                            <div className="col-12 col-md-12" >
                                <div style={{ padding: 10, backgroundColor: '#F0F3F6' }}>
                                    <div className="row" style={{ margin: 0 }}>
                                        {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2" style={{ display: 'grid', alignItems: 'end' }}>
                                            <p style={{ color: 'grey', marginBottom: 5, fontSize: 13 }}>Desempenho do sistema de LR*</p>
                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM DADOS QUANTITATIVOS</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>

                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>40%</p>
                                                </div>

                                            </div>
                                            <div style={{ width: '100%', height: 5, backgroundColor: 'lightgrey', borderRadius: 2, marginTop: 10 }}>
                                                <div style={{ width: '40%', height: 5, backgroundColor: '#3DB867', borderRadius: 2 }}>
                                                </div>

                                            </div>
                                        </div> */}

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2" style={{ display: 'grid', alignItems: 'end' }}>
                                            <p style={{ color: 'grey', marginBottom: 5, fontSize: 13 }}>Financeiro</p>
                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM R$</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>

                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', textAlign: 'end' }}>{this.state.saldos_graficos[1] == 0 || this.state.saldos_graficos[0] == 0 ? 'Sem dados para comparação' : (((100 * this.state.saldos_graficos[0]) / this.state.saldos_graficos[1]) - 100 > 100 ? '+100' : maskNumberBr(parseFloat(((100 * this.state.saldos_graficos[0]) / this.state.saldos_graficos[1]) - 100).toFixed(0)) + '%')}</p>
                                                </div>

                                            </div>
                                            {<div style={{ width: '100%', height: 5, backgroundColor: 'lightgrey', borderRadius: 2, marginTop: 10 }}>
                                                {this.state.saldos_graficos[1] == 0 || this.state.saldos_graficos[0] == 0 ? '' : <div style={{ width: ((100 * this.state.saldos_graficos[0]) / this.state.saldos_graficos[1]) - 100 > 100 ? '+100%' : (parseFloat(((100 * this.state.saldos_graficos[0]) / this.state.saldos_graficos[1]) - 100).toFixed(0)) + '%', height: 5, backgroundColor: '#3BBEDF', borderRadius: 2 }}>
                                                </div>}

                                            </div>}
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2" style={{ display: 'grid', alignItems: 'end' }}>
                                            <p style={{ color: 'grey', marginBottom: 5, fontSize: 13 }}>Resíduos</p>
                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM KG</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>

                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', textAlign: 'end' }}>{this.state.pesos_grafico[1] == 0 || this.state.pesos_grafico[0] == 0 ? 'Sem dados para comparação' : (((100 * this.state.pesos_grafico[0]) / this.state.pesos_grafico[1]) - 100 > 100 ? '+100' : maskNumberBr(parseFloat(((100 * this.state.pesos_grafico[0]) / this.state.pesos_grafico[1]) - 100).toFixed(0)) + '%')}</p>
                                                </div>

                                            </div>
                                            <div style={{ width: '100%', height: 5, backgroundColor: 'lightgrey', borderRadius: 2, marginTop: 10 }}>
                                                {this.state.pesos_grafico[1] == 0 || this.state.pesos_grafico[0] == 0 ? '' : <div style={{ width: ((100 * this.state.pesos_grafico[0]) / this.state.pesos_grafico[1]) - 100 > 100 ? '+100%' : (parseFloat(((100 * this.state.pesos_grafico[0]) / this.state.pesos_grafico[1]) - 100).toFixed(0)) + '%', height: 5, backgroundColor: '#3BBEDF', borderRadius: 2 }}>
                                                </div>}

                                            </div>
                                        </div>



                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2" style={{ display: 'grid', alignItems: 'end' }}>
                                            <p style={{ color: 'grey', marginBottom: 5, fontSize: 13 }}>Ecopontos ativos</p>
                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM UNIDADES ATIVAS</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>

                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', textAlign: 'end' }}>{this.state.pontos_coleta_grafico[1] == 0 || this.state.pontos_coleta_grafico[0] == 0 ? 'Sem dados para comparação' : (((100 * this.state.pontos_coleta_grafico[0]) / this.state.pontos_coleta_grafico[1]) - 100 > 100 ? '+100' : maskNumberBr(parseFloat(((100 * this.state.pontos_coleta_grafico[0]) / this.state.pontos_coleta_grafico[1]) - 100).toFixed(0)) + '%')}</p>
                                                </div>

                                            </div>
                                            <div style={{ width: '100%', height: 5, backgroundColor: 'lightgrey', borderRadius: 2, marginTop: 10 }}>
                                                {this.state.pontos_coleta_grafico[1] == 0 || this.state.pontos_coleta_grafico[0] == 0 ? '' : <div style={{ width: ((100 * this.state.pontos_coleta_grafico[0]) / this.state.pontos_coleta_grafico[1]) - 100 > 100 ? '+100%' : (parseFloat(((100 * this.state.pontos_coleta_grafico[0]) / this.state.pontos_coleta_grafico[1]) - 100).toFixed(0)) + '%', height: 5, backgroundColor: '#3BBEDF', borderRadius: 2 }}>
                                                </div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2" style={{ display: 'grid', alignItems: 'end' }}>
                                            <p style={{ color: 'grey', marginBottom: 5, fontSize: 13 }}>Solicitação de coleta</p>
                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM DADOS QUANTITATIVOS</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>

                                                    <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', textAlign: 'end' }}>{this.state.solicitacoes_grafico[1] == 0 || this.state.solicitacoes_grafico[0] == 0 ? 'Sem dados para comparação' : (((100 * this.state.solicitacoes_grafico[0]) / this.state.solicitacoes_grafico[1]) - 100 > 100 ? '+100' : maskNumberBr(parseFloat(((100 * this.state.solicitacoes_grafico[0]) / this.state.solicitacoes_grafico[1]) - 100).toFixed(0)) + '%')}</p>
                                                </div>

                                            </div>
                                            <div style={{ width: '100%', height: 5, backgroundColor: 'lightgrey', borderRadius: 2, marginTop: 10 }}>
                                                {this.state.solicitacoes_grafico[1] == 0 || this.state.solicitacoes_grafico[0] == 0 ? '' : <div style={{ width: ((100 * this.state.solicitacoes_grafico[0]) / this.state.solicitacoes_grafico[1]) - 100 > 100 ? '+100%' : (parseFloat(((100 * this.state.solicitacoes_grafico[0]) / this.state.solicitacoes_grafico[1]) - 100).toFixed(0)) + '%', height: 5, backgroundColor: '#3BBEDF', borderRadius: 2 }}>
                                                </div>}

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>}


                    </div>
                    <br />
                    <div className="row " >
                        <div className="col-12 col-sm-6 col-md-6 mt-2" >
                            <div className="card" >

                                <div style={{ height: 80, backgroundColor: '#35589D', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES QUANTIDADES ({this.state.unity})</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>{this.state.residuo_maior_kg.length == 0 ? 0 : maskNumberBr(this.state.residuo_maior_kg[0].soma)} {this.state.unity}</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>{this.state.residuo_maior_kg.length == 0 ? '-' : this.state.residuo_maior_kg[0].nome}</p>

                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>{this.state.residuo_maior_kg.length == 0 ? '0%' : maskNumberBr(parseFloat((parseFloat(this.state.residuo_maior_kg[0].soma) / parseFloat(this.state.total_kg)) * 100).toFixed(2)) + '%'}</p>

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 mt-2" >
                            <div className="card" >

                                <div style={{ height: 80, backgroundColor: '#33AEF3', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES VALORES (R$)</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>{this.state.residuo_maior_valor.length == 0 ? maskPrice('0.00') : 'R$ ' + maskNumberBr(parseFloat(this.state.residuo_maior_valor[0].soma).toFixed(2))}</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>{this.state.residuo_maior_valor.length == 0 ? '-' : this.state.residuo_maior_valor[0].nome}</p>

                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>{this.state.residuo_maior_valor.length == 0 ? '0%' : maskNumberBr(parseFloat(((parseFloat(this.state.residuo_maior_valor[0].soma) / parseFloat(this.state.total_valor[0].soma)) || 0) * 100).toFixed(2)) + '%'}</p>

                                    </div>

                                </div>

                            </div>
                        </div>

                        {/* <div className="col-12 col-sm-6 col-md-4 mt-2" >
                            <div className="card" >

                                <div style={{ height: 80, backgroundColor: '#3C75B9', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIS RESÍDUOS ENVIADOS</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>120 Kg</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>Nome do resíduo</p>

                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>Z%</p>

                                    </div>

                                </div>

                            </div>
                        </div> */}



                    </div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6 ">


                                    <div className="row">
                                        <div className="col-12 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#3CBFDF', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Quantidade de resíduos no mês</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.quantidade_residuos_coletados)}</h4>
                                            </div>

                                        </div>
                                        {/* <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#F5545B', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Valor movimentado (R$) nos últimos 30 dias</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskPrice(parseFloat(this.state.saldo_financeiro).toFixed(2))}</h4>
                                            </div>

                                        </div> */}
                                        <div className="col-12 ">
                                            <hr className="mt-3 mb-1" />
                                        </div>

                                        <div className="col-12 ">
                                            {this.state.lista_rr.map((item, id) => (
                                                <div key={id} className="row mt-3">
                                                    <div className="col-12" style={{ margin: 'auto auto' }}>
                                                        <div className='d-flex justify-content-between'>
                                                            <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>{item.nome}</p>
                                                            <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat((parseFloat(item.soma_kg) / parseFloat(this.state.total_kg)) * 100).toFixed(2)) + '%'}</p>
                                                        </div>
                                                        <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                            <div style={{ width: parseFloat((parseFloat(item.soma_kg) / parseFloat(this.state.total_kg)) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#3CBFDF', borderRadius: 2 }}>
                                                            </div>

                                                        </div>
                                                        {/* <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2, marginTop: 2 }}>
                                                            <div style={{ width: '80%', height: 5, backgroundColor: '#F5545B', borderRadius: 2 }}>
                                                            </div>

                                                        </div> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>



                                <div className="col-12 col-md-6 ">
                                    <br className="d-block d-md-none" />
                                    <br className="d-block d-md-none" />

                                    <div className="row">
                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#FABB00', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Solicitações de coleta no mês</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.num_solicitacoes)}</h4>
                                            </div>

                                        </div>
                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#3FBE64', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Ecopontos ativos</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.num_ecopontos_ativos)}</h4>
                                            </div>

                                        </div>
                                        <div className="col-12 ">

                                            <hr className="mt-3 mb-1" />
                                        </div>

                                        <div className="col-12 ">
                                            <div className="row mt-3">

                                                <div className="col-12" style={{ margin: 'auto auto' }}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Aguardando</p>
                                                        <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_aguardando / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_aguardando / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12" style={{ margin: 'auto auto' }}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Iniciado</p>
                                                        <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_andamento / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_andamento / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12" style={{ margin: 'auto auto' }}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Finalizado</p>
                                                        <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_finalizado / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_finalizado / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr className="mt-3 mb-1" />
                                            {this.state.ponto_coleta.map((item, id) => (

                                                <div key={id} className="row mt-3">
                                                    <div className="col-12" style={{ margin: 'auto auto' }}>
                                                        <div className="d-flex justify-content-between">
                                                            <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>{item.nome_fantasia}</p>
                                                            <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(item.soma) + ' ' + this.state.unity}  <span style={{ color: "grey", fontSize: 15 }}>{maskNumberBr(parseFloat(100 * ((parseFloat(item.soma) / this.state.total_kg) || 0)).toFixed(2)) + '%'}</span></p>
                                                        </div>

                                                        <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                            <div style={{ width: parseFloat(100 * ((parseFloat(item.soma) / this.state.total_kg) || 0)).toFixed(2) + '%', height: 5, backgroundColor: '#3FBE64', borderRadius: 2 }}>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>))}


                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_dados_dashboard(page.selected + 1);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Dashboard);


