import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { logout } from "../../../../actions/AppActions";
import { connect } from "react-redux";

import { URL, URLopen } from "../../../../variables";

import { MdReply } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";

class ConvidarInterno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading_screen: false,
      loading_area: false,
      path: "/relacionamentos_externos",
      loading_save: false,
      msgErro: "",

      enterprises_data: [],
      enterprise_picked: [],
      owner_name: "",

      area: "",
      name: "",
      email: "",
    };
  }

  handleArea(event) {
    this.setState({ area: event.target.value, enterprise_picked: [] });
    if (event.target.value === "Destino") {
      this.setState({ loading_save: true });
      fetch(`${URL}api/get_destinos`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`,
          // "Content-Type": "application/json",
        },
      }).then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          this.setState({
            enterprises_data: resp.destinos,
            loading_save: false,
          });
        } catch (err) {
          console.log(err);
        }
      });
      return;
    }
    // if (event.target.value === 'Estoque') {
    //     this.setState({ loading_save: true })
    //     fetch(`${URL}api/get_estoques`, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             'Authorization': `Bearer ${this.props.token}`,
    //             // "Content-Type": "application/json",
    //         },
    //     })
    //         .then(async (responseLog) => {
    //             try {
    //                 let resp = await responseLog.json();
    //                 this.setState({ enterprises_data: resp.estoques, loading_save: false })
    //             }
    //             catch (err) {
    //                 console.log(err)
    //             }
    //         })
    //     return;
    // }
    // if (event.target.value === 'Transportadora') {
    //     this.setState({ loading_save: true })
    //     fetch(`${URL}api/get_transportadoras`, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             'Authorization': `Bearer ${this.props.token}`,
    //             // "Content-Type": "application/json",
    //         },
    //     })
    //         .then(async (responseLog) => {
    //             try {
    //                 let resp = await responseLog.json();
    //                 this.setState({ enterprises_data: resp.transportadoras, loading_save: false })
    //                 console.log(resp)
    //             }
    //             catch (err) {
    //                 console.log(err)
    //             }
    //         })
    //     return;
    // }
    else {
      console.log(event.target.value);
      return;
    }
  }

  handleEnterprise(event) {
    let empresa = this.state.enterprises_data.find(
      (item) => item.id === event.target.value
    );
    this.setState({
      enterprise_picked: event.target.value,
      name: empresa.user_id.name,
      email: empresa.user_id.email,
    });
  }

  convidar() {
    let empresa = this.state.enterprises_data.find(
      (item) => item.id === this.state.enterprise_picked
    );
    console.log("empresa", empresa);
    if (!this.state.area) {
      this.setState({ msgErro: "Selecione uma área" });
      return;
    }
    if (!empresa) {
      this.setState({ msgErro: "Selecione uma empresa" });
      return;
    }
    if (!this.state.name || !this.state.email) {
      this.setState({
        msgErro: "Preencha os dados do responsável corretamente",
      });
      return;
    }

    this.setState({ loading_save: true });
    toast.loading("Salvando dados...", {
      toastId: "toastpromisse" + this.state.email,
      hideProgressBar: false,
    });
    fetch(`${URLopen}api/auth/invite_internal`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        external_destination_id: empresa.id,
        company_name: empresa.razao,
        commercial_name: empresa.fantasia,
        name: this.state.name,
        email: this.state.email,
        wastes_rejects: empresa.residuos_rejeitos,

        cnpj: empresa.cnpj,
        cep: empresa.cep,
        city: empresa.cidade,
        state: empresa.estado,
        street: empresa.logradouro,
        number: empresa.numero,
        nbhd: empresa.bairro,
        comp: empresa.complemento,
        enterprise_type: this.state.area,

        phone: empresa.user_id.user_dado.telefone,

        organization_url: URL,
        organization_user_id: this.props.user.id,
        organization_user_nome: this.props.user.name,
        organization_user_email: this.props.user.email,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);

            this.setState({ loading_save: false, msgErro: erro });

            toast.update("toastpromisse" + this.state.email, {
              render: "Erro",
              type: "error",
              isLoading: false,
              autoClose: 1000,
            });
          } else {
            toast.update("toastpromisse" + this.state.email, {
              render: "Convite enviado por e-mail. Sucesso",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
            console.log(resp);
            setTimeout(() => {
              this.setState({ loading_save: false, redirect: true });
            }, 2500);
          }
        } catch (err) {
          console.log(err);
          toast.update("toastpromisse" + this.state.email, {
            render: "Erro",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
          this.setState({
            loading_save: false,
            msgErro: "Erro ao pegar resposta do servidor",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update("toastpromisse" + this.state.email, {
          render: "Erro",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        this.setState({
          loading_save: false,
          msgErro:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
        });
      });
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div className="col-12">
          <hr />
          {this.state.loading_screen == false && (
            <div>
              {this.state.msgErro != "" && (
                <div>
                  <div
                    className="alert alert-danger alert-dismissible fade show mb-0"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => this.setState({ msgErro: "" })}
                    ></button>

                    <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                      {this.state.msgErro}
                    </p>
                  </div>
                  <br />
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Área <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <select
                        value={this.state.area}
                        className="form-control"
                        id="areas"
                        onChange={(e) => {
                          this.handleArea(e);
                        }}
                      >
                        <option value={""}>
                          Selecione uma área de atuação
                        </option>
                        <option value={"Destino"}>Destino</option>
                        {/* <option value={'Estoque'}>Estoque</option>
                                                <option value={'Transportadora'}>Transportadora</option> */}
                      </select>
                    </div>
                  </div>
                  {this.state.area === "Destino" &&
                    !this.state.loading_save && (
                      <div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            Empresa <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="col-sm-12">
                            <select
                              value={this.state.enterprise_picked}
                              className="form-control"
                              id="areas"
                              onChange={(e) => {
                                this.handleEnterprise(e);
                              }}
                            >
                              <option value={""}>Selecione um destino</option>
                              {this.state.enterprises_data.map((item, id) => (
                                <option key={id} value={item.id}>
                                  {item.fantasia} - {item.user_id.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            Nome <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="col-sm-12">
                            <input
                              className="form-control"
                              placeholder="Nome do convidado"
                              type="text"
                              onChange={(e) =>
                                this.setState({ name: e.target.value })
                              }
                              value={this.state.name}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            E-mail <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="col-sm-12">
                            <input
                              className="form-control"
                              placeholder="E-mail da pessoa"
                              type="email"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              value={this.state.email}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {/* {this.state.area === 'Estoque' && !this.state.loading_save && <div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Empresa <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.enterprise_picked} className="form-control" id="areas" onChange={(e) => {
                                                    this.setState({ enterprise_picked: e.target.value })
                                                    console.log(e.target.value)
                                                }}>
                                                    <option value={''}>Selecione um estoque</option>
                                                    {this.state.enterprises_data.map((item, id) =>
                                                        <option value={item.id}>{item.nome_fantasia} - {item.responsavel.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.area === 'Transportadora' && !this.state.loading_save && <div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Empresa <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.enterprise_picked} className="form-control" id="areas" onChange={(e) => {
                                                    this.setState({ enterprise_picked: e.target.value })
                                                    console.log(e.target.value)
                                                }}>
                                                    <option value={''}>Selecione uma transportadora</option>
                                                    {this.state.enterprises_data.map((item, id) =>
                                                        <option value={item.id}>{item.nome_fantasia} - {item.responsavel.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>} */}
                </div>
              </div>
              {this.state.loading_save == false && (
                <div className="row mt-4">
                  <div
                    className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Link to="/relacionamentos_externos">
                      <span className="btn btn-secondary btn-lg waves-effect waves-light">
                        <MdReply /> Voltar
                      </span>
                    </Link>
                  </div>
                  <div
                    className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <button
                      type="button"
                      onClick={() => this.convidar()}
                      disabled={this.state.loading_save}
                      className="btn btn-success btn-lg waves-effect waves-light"
                      style={{ backgorundColor: "#f2f2f2" }}
                    >
                      Convidar pessoa
                    </button>
                  </div>
                </div>
              )}
              {this.state.loading_save == true && (
                <div className="row">
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.loading_screen == true && (
            <div className="row">
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )}
          <ToastContainer
            position="bottom-right"
            toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={true}
            pauseOnHover
          />
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(ConvidarInterno);
