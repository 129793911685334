import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert, Checkbox, Chip } from "@mui/material";
import { maskNumberBr, maskPrice } from "../../Auxiliar/Masks";
import { URL } from "../../variables";

const style = {
  position: "absolute",
  right: "50%",
  transform: "translate(0, 0, 0, 50%)",
  width: "50%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,

  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "5%",
  },
};

export default function DadosFiscaisModal(props) {
  const [open, setOpen] = React.useState(false);

  const [state, setState] = React.useState({
    showWarning: false,
    showWarning2: false,
    showWarningDiffDestinos: false,
    msgError: "",
  });

  const [fiscalData, setFiscalData] = React.useState({
    chave: "",
    numero: "",
    serie: "",
    AnexoFiscal: null,
    NameAnexo: null,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFiscalData({ ...fiscalData, chave: "", numero: "", serie: "", AnexoFiscal: "" });
  };

  const checkDestinoId = () => {
    if (props.destinacoes.length >= 1) {
      const firstDestinoId = props.destinacoes[0].destino_id;

      for (let i = 1; i < props.destinacoes.length; i++) {
        if (props.destinacoes[i].destino_id !== firstDestinoId) {
          setState({ ...state, showWarningDiffDestinos: true });
          return;
        }
      }
      setState({ ...state, showWarningDiffDestinos: false });
    }
  };

  function openModal() {
    if (props.destinacoes.length == 0) {
      setState({ ...state, showWarning: true });
      return;
    }
    checkDestinoId();
    handleOpen();
  }

  function saveFiscalData() {
    fetch(`${URL}api/store_dados_fiscais_destinacoes_internas`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chave_nota: fiscalData.chave,
        serie_nota: fiscalData.serie,
        numero_nota: fiscalData.numero,
        anexo: fiscalData.AnexoFiscal?.split(',')[1] ?? null,
        destinacoes: props.destinacoes,
      }),
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        if (resp.error != null) {
          setState({
            ...state,
            msgError: "Preencha os campos corretamente",
            showWarning2: false,
          });
          return;
        } else {
          setState({
            ...state,
            msgError: "",
            showWarning2: false,
          });
          props.updateDestinacoes();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  }

  function getFileBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFiscalData({ ...fiscalData, AnexoFiscal: reader.result, NameAnexo: file.name })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <>
      <button
        className="btn btn-primary btn-lg waves-effect waves-light mx-2 mt-1"
        onClick={() => openModal()}
      >
        Atribuir Nota-Fiscal
      </button>

      <SweetAlert
        danger
        title={"Ops..."}
        onConfirm={() => {
          setState({ ...state, showWarning: false });
        }}
        show={state.showWarning}
        confirmBtnText="Entendi"
        confirmBtnBsStyle="primary"
      >
        Selecione pelo menos uma destinação para inserir seus dados fiscais
      </SweetAlert>

      <SweetAlert
        warning
        title={"Atenção"}
        onConfirm={() => {
          saveFiscalData();
        }}
        onCancel={() => {
          setState({ ...state, showWarning2: false });
        }}
        show={state.showWarning2}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="error"
      >
        Existem destinos diferentes selecionados para uma mesma nota fiscal,
        deseja continuar mesmo assim?
      </SweetAlert>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {state.msgError != "" && (
              <Alert severity="error">{state.msgError}</Alert>
            )}
            <section id="fiscal data">
              <label
                className="col-sm-12 col-form-label"
                style={{ color: "black", fontSize: "1.5rem" }}
              >
                Dados fiscais
              </label>
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Chave da NF-e <b style={{ color: "red" }}>*</b>
                </label>
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    placeholder="Chave de acesso da nota fiscal"
                    value={fiscalData.chave}
                    onChange={(e) =>
                      setFiscalData({ ...fiscalData, chave: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Número da NF-e <b style={{ color: "red" }}>*</b>
                </label>
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    placeholder="Número da nota fiscal"
                    value={fiscalData.numero}
                    onChange={(e) =>
                      setFiscalData({ ...fiscalData, numero: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Série da NF-e <b style={{ color: "red" }}>*</b>
                </label>
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    placeholder="Série da nota fiscal"
                    value={fiscalData.serie}
                    onChange={(e) =>
                      setFiscalData({ ...fiscalData, serie: e.target.value })
                    }
                  />
                </div>
              </div>
              <label className="input-group mt-3" htmlFor='pdf-input' style={{ cursor: 'pointer' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text">Procurar...</span>
                </div>
                <div className="form-control" >
                  Clique aqui para adicionar um anexo fiscal.
                </div>
              </label>
              <input
                id='pdf-input'
                className="d-none"
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                onChange={(e) => {
                  getFileBase64(e.target.files[0]);
                }}
              />
              {fiscalData.AnexoFiscal && (
                <p className='mt-2'>
                  Prévia do AnexoFiscal atual:{" "}
                  <a href={fiscalData.AnexoFiscal} target='_blank'>{fiscalData.NameAnexo !== null ? fiscalData.NameAnexo : fiscalData.AnexoFiscal}</a>
                </p>
              )}
              <div
                className="col-12 d-flex justify-content-end mt-3"
                style={{ marginBottom: "0.5rem" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    if (state.showWarningDiffDestinos) {
                      setState({ ...state, showWarning2: true });
                    } else {
                      saveFiscalData();
                    }
                  }}
                  className="btn btn-success btn-lg waves-effect waves-light"
                >
                  Salvar dados fiscais
                </button>
              </div>
            </section>
            <hr />
            <section>
              <Alert severity="warning">
                Verifique se as destinações selecionadas estão corretas.{" "}
                {state.showWarningDiffDestinos &&
                  "Destinos diferentes foram selecionados, tenha certeza antes de atribuir a mesma NF-e para todas as destinações internas."}
              </Alert>
              <div className="mt-3">
                <ul class="list-group list-group-light">
                  {props.destinacoes.map((item, id) => (
                    <li
                      key={item.id}
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{item.destino_fantasia}</p>
                          <p class="text-muted mb-0">
                            {maskNumberBr(item.peso_total)}kg |{" "}
                            {maskPrice(item.saldo)} |{" "}
                            {item.data_criacao.replace(
                              /(\d*)-(\d*)-(\d*).*/,
                              "$3/$2/$1"
                            )}
                          </p>
                        </div>
                      </div>
                      <Chip
                        label={item.situacao}
                        color={item.finalizada ? "warning" : "info"}
                        size="small"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
