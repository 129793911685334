
import React from 'react';
import Filter from '../../../Auxiliar/Filter';
import ListaRelatoriosColetaTransporte from './ListaRelatoriosColetaTransporte';

const SelecionarJsRelatoriosColeta = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        system: { value: true, label: 'Relatórios de Coleta e Transporte', checked: true },
    })

    const getStatus = () => {
        return Object.values(options).find(item => item.value === true);
    }

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Dashboard</h4>
                    <Filter
                        setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch}
                    />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Lista de relatórios e indicadores disponíveis para melhor gestão</p>
                </div>
                <div className="row">
                    <ListaRelatoriosColetaTransporte dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsRelatoriosColeta
