import React from 'react'
import { MdClose } from 'react-icons/md'
import { months, years } from './options'

const DashboardFilter = ({ filterYear, filterMonth, filterUnity, filterRrs, optionsRr, selectedRr, getData, setState }) => {

  const handleRRChange = ({ target }) => {
    if (filterRrs.find(id => id === target.value)) {
      setState({
        selected_rr: target.value,
      })
      return
    }

    setState({
      selected_rr: target.value,
      filter_rrs: target.value != '' ? [...filterRrs, target.value] : filterRrs,
    })
  }

  return (
    <div className="col-12 rounded bg-white my-3 p-3" style={{
      borderTop: '2px solid #007bff',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
    }}>
      <form className="row mx-3 justify-content-between" onSubmit={(e) => { e.preventDefault(); getData() }}>
        <p className='bold m-0 p-0'>Filtros</p>

        <div style={{ width: 'auto',padding:0 }}>

          <label htmlFor="">De</label>
          <input className="form-control" value={filterMonth} name='De' type="date" onChange={({ target }) => setState({ filter_month: target.value })} />
        </div>

        {/* <select value={filterMonth} onChange={({ target }) => setState({ filter_month: target.value })} name="" id="" className="form-select ms-1">
              {months.map(item => (
                <option value={item.value} key={item.value}>{item.name}</option>
              ))}
            </select> */}
        <div style={{ width: 'auto',padding:0 }} >

          <label htmlFor="">Até</label>

          <input className="form-control" value={filterYear} name='Até' type="date" onChange={({ target }) => setState({ filter_year: target.value })} />
        </div>

        {/* <select value={filterYear} onChange={({ target }) => setState({ filter_year: target.value })} name="" id="" className="form-select ms-2">
              {years.map(item => (
                <option value={item} key={item}>{item}</option>
              ))}
            </select> */}
        <div style={{ width: 'auto',padding:0 }} >

          <label className="">Tipo</label>
          <select value={filterUnity} onChange={({ target }) => setState({ filter_unity: target.value })} name="" id="" className="form-select ms-1">
            <option value="peso">KG</option>
            <option value="volume">LT</option>
          </select>
        </div>
        <div style={{ width: 'auto',padding:0 }} >
          <label >Resíduo</label>
          <select value={selectedRr} onChange={handleRRChange} name="" id="" className="form-select ms-1" placeholder='Selecione um ou mais resíduos'>
            <option value="">Selecione um ou mais resíduos</option>
            {optionsRr.map(item => {
              if (!filterRrs.find(id => id === item.id)) {
                return <option key={item.id} value={item.id}>{item.nome}</option>
              }
            })}
          </select>

        </div>

        <div style={{ width: 'auto',padding:0 }}>

          <button type='submit' className="btn text-white" style={{ backgroundColor: '#007bff',marginTop:24 }}>Buscar</button>

        </div>




        {filterRrs.length > 0 &&
          <div className="d-flex m-0 p-0 align-items-center flex-wrap mt-3">
            <p className='bold m-0 p-0'>Resíduos/Rejeitos selecionados: </p>
            {filterRrs.map((item, index) => (
              <span className='select-card m-2' key={index}>
                {optionsRr.find(rr => rr.id === item).nome}

                <span className="select-card-close" onClick={() => setState({ filter_rrs: filterRrs.filter(id => id !== item) })}>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <MdClose color='#FFF' />
                  </div>
                </span>
              </span>
            ))}
          </div>}

      </form>
    </div>
  )
}

export default DashboardFilter