import React from 'react'
import DespachoPendente from './DespachoGeral';
import DespachoFinalizada from './DespachoFinalizada';
import DestinacaoGeral from '../Destinacao/DestinacaoGeral';
import SolicitacaoGeral from '../SolicitarColeta/SolicitacaoGeral';
import DestinacaoFinalizadas from '../Destinacao/DestinacaoFinalizadas';
import SolicitacaoFinalizada from '../SolicitarColeta/SolicitacaoFinalizada';
import Filter from '../../../Auxiliar/Filter';

const Despacho = () => {
  const [search, setSearch] = React.useState(false)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState({
    despachoPendente: { value: true, label: 'Despachos Pendentes', checked: true },
    despachoFinalizado: { value: false, label: 'Despachos Finalizados', checked: false },
    destinacaoGeral: { value: false, label: 'Destinações Pendentes', checked: false },
    destinacaoFinalizada: { value: false, label: 'Destinações Finalizadas', checked: false },
    solicitacaoGeral: { value: false, label: 'Solicitações Gerais', checked: false },
    solicitacaoAguardandoDespacho: { value: false, label: 'Solicitações em Aguardo', checked: false },
    solicitacaoFinalizada: { value: false, label: 'Solicitações Finalizadas', checked: false },
  })

  return (
    <div className="card">
      <div className="card-body" id="card">
        <div className="d-flex align-items-start">
          <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Despachos</h4>
          <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
        </div>
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os despachos do sistema</p>
        </div>
        <div className="row">
          {options.solicitacaoGeral.value &&
            <SolicitacaoGeral dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} emAguardo={false} />}
          {options.solicitacaoAguardandoDespacho.value &&
            <SolicitacaoGeral dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} emAguardo={true} />}
          {options.solicitacaoFinalizada.value &&
            <SolicitacaoFinalizada dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.despachoPendente.value &&
            <DespachoPendente dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.despachoFinalizado.value &&
            <DespachoFinalizada dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.destinacaoGeral.value &&
            <DestinacaoGeral dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.destinacaoFinalizada.value &&
            <DestinacaoFinalizadas dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
        </div>
      </div>
    </div>
  )
}

export default Despacho