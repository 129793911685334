import React, { Component } from "react";
import { MdSearch } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, withRouter, Link } from "react-router-dom";

import { logout } from "../../../actions/AppActions";
import { FaFileAlt } from "react-icons/fa";
import { THIS_URL, URL, URLadmin } from "../../../variables";
import { Alert, IconButton } from "@mui/material";
import { BsBarChart, BsBarChartFill } from "react-icons/bs";
import moment from "moment";

class ListaRelatoriosGestaoInterna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true,
      path: "/relatorios/gestao_interna",
      loading: false,
      page: 1,
      total_pages: 1,
      msgErro: "",
      search: "",
      dateOf: "",
      dateFor: "",
      relatorios: []
    };
  }


  componentDidMount() {
    this.getData(this.state.page);
    this.props.setSearch(false)
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false)
      this.getData(this.state.page);
    }
  }

  async getData(page, search = '', busca = false) {
    if (busca == true) {
      if (this.state.loading_search == false) {
        this.setState({ loading_search: true });
      }
    } else if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }

    fetch(`${URL}api/get_superadmin_relatorios/gi?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ redirect: true });
        }
        else {
          this.setState({
            relatorios: resp.relatorios,
            loading: false,
            page: resp.pagination.current_page,
            total_pages: resp.pagination.last_page,
          })
        }
      } catch (err) {
        this.setState({ redirect: true });
        console.log(err);
      }

    })
      .catch((err) => {
        this.setState({ redirect: true });
        console.log(err);
      });
  }

  handleSearch({ value }) {
    clearTimeout(this.timeout);
    this.setState({ search: value })
    this.timeout = setTimeout(() => this.props.setAllow(true), 750);
  }

  render() {
    return (
      <>
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div>
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <form
                className="app-search mt-1"
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  clearTimeout(this.pesquisarTimeout);
                  this.setState({ page: 1 });
                  this.getData(1, this.state.search, true);
                  e.preventDefault();
                }}
              >
                <div
                  className="form-group mb-0"
                  style={{ width: "100%", position: "relative" }}
                >
                  <input
                    name="search"
                    placeholder="Pesquisar pelo nome do relatório..."
                    className="form-control"
                    value={this.state.search}
                    style={{
                      width: "100%",
                      paddingRight: 40,
                      borderRadius: 100,
                    }}
                    onChange={(e) => {
                      clearTimeout(this.pesquisarTimeout);
                      let search = e.target.value;
                      this.setState({ page: 1, search: search });
                      this.pesquisarTimeout = setTimeout(() => {
                        this.getData(1, search, true);
                      }, 500);
                    }}
                  />
                  <div
                    style={{
                      width: 40,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdSearch />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {this.state.loading && <div className="d-flex justify-content-center my-3">
            <div className="spinner-border text-primary" role="status"></div>
          </div>}
          {this.state.relatorios.length == 0 && !this.state.loading && <Alert severity="info">Nenhum relatório encontrado</Alert>}
          {this.state.relatorios.length > 0 && !this.state.loading && <div className="table-rep-plugin">
            <div
              // className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <table
                id="tech-companies-1"
                className="table table-striped table-hover"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th><FaFileAlt /></th>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th>Data da criação</th>
                  </tr>
                </thead>
                {/* dados da lista ----------------------------------- */}
                <tbody>
                  {this.state.relatorios.map((item, id) =>
                    <tr key={id}>
                      <td>
                        {item.iframe && <Link to={'gestao_interna/' + item.id}>
                          <img src={URLadmin + item.tag_icon} style={{
                            background: '#690096',
                            width: '3rem',
                            height: '3rem',
                            borderRadius: '50%',
                            textAlign: 'center',
                            lineHeight: '3rem',
                            verticalAlign: 'middle',
                            padding: '1px'
                          }} />
                        </Link>}
                        {!item.iframe &&
                          <a href={item.link} target="_self">
                            <img src={URLadmin + item.tag_icon} style={{
                              background: '#690096',
                              width: '3rem',
                              height: '3rem',
                              borderRadius: '50%',
                              textAlign: 'center',
                              lineHeight: '3rem',
                              verticalAlign: 'middle',
                              padding: '1px'
                            }} />
                          </a>}
                      </td>
                      <td>{item.name}</td>
                      <td>{item.tag_name}</td>
                      <td>{moment(item.created_at).calendar()}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>}

          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próxima"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                initialPage={this.state.page - 1}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page.selected + 1 });
      this.getData(page.selected + 1)
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default withRouter(connect(mapsStateToProps, { logout })(ListaRelatoriosGestaoInterna));
