
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { BsFillInfoCircleFill, BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

// users
import { URL } from '../../../variables';
import { MdAdd, MdReply } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import GetToken from '../../SigorRequests/GetToken';
import GetRRClass from '../../SigorRequests/GetRRClass';
import GetAcondicionamentos from '../../SigorRequests/GetAcondicionamentos';
import { IconButton, Tooltip } from '@mui/material';
import VerifySigor from '../../SigorRequests/VerifySigor';

class EditarRR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/residuos_rejeitos',
            loading_screen: false,
            classes: [],
            periculosidades: [],
            estados: [],
            acondicionamentos: [],
            acondicionamentosSigor: [],
            destinacoes: [],
            armazenamentos: [],
            tratamentos: [],
            unidades: [],
            sub_materiais: [],

            submaterial: '',
            classe: '',
            periculosidade: '',
            estado: '',
            acondicionamento: '',
            destinacao: '',
            armazenamento: '',
            tratamento: '',
            unidade_peso: '',
            unidade_volume: '',
            tipo_medida_principal: '',
            tipo_movimentacao_financeira: '',
            valor_saldo: '',
            autorizacao: '',
            equivalencia: '',
            medida_principal: '',
            medida_secundaria: '',
            descricao: '',
            nome: '',
            sigla: '',
            tipo: '',
            loading_save: false,
            msgErro: '',
            sigorToken: '',

            e_sigor: false,
            residuo_id: '',
            residuos: [],
            residuos_selecionados: [],
            openInfo: false,

            marNumeroOnu: '',
            marClasseRisco: '',
            marNomeEmbarque: '',
            greCodigo: '',
            danger: false,
        };
    }



    salvar() {
        let sub_materias = this.state.sub_materiais.filter((item) => item.nome != null);

        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_residuos_rejeitos`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                submateriais: sub_materias,
                tipo: this.state.tipo,
                classe_id: this.state.classe,
                periculosidade_id: this.state.periculosidade,
                estado_id: this.state.estado,
                acondicionamento_id: this.state.acondicionamento,
                destinacao_id: this.state.destinacao,
                armazenamento_id: this.state.armazenamento,
                tratamento_id: this.state.tratamento,
                descricao: this.state.descricao,
                unidade_principal_id: this.state.medida_principal,
                unidade_secundaria_id: this.state.medida_secundaria,
                equivalencia: this.state.equivalencia,
                autorizacao_movimentacao: this.state.autorizacao === 'Sim' ? true : (this.state.autorizacao === 'Não' ? false : ''),
                saldo_financeiro: this.state.tipo_movimentacao_financeira,
                valor_saldo_financeiro: this.state.valor_saldo,
                rr_id: this.props.match.params.id,
                residuos_selecionados: this.state.residuos_selecionados
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        this.get_rr(this.props.match.params.id);
        this.fill_residuos();
    }

    get_rr(id) {
        console.log('props super', this.props)

        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_residuo_rejeito/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message === "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    console.log('resp', resp)
                    let token = ''
                    let codigos_risco = {}
                    let danger = false
                    let classes = resp.classes
                    let estados = resp.estados
                    let tratamentos = resp.tratamentos
                    if (resp.residuo_rejeito.e_sigor) {
                        Array.from(resp.residuo_rejeito.sigor_residuo_codigo.residuo_rejeito_codigo).forEach(item => {
                            if (item === '*') {
                                danger = true
                            }
                        })
                        estados = estados.filter(item => item.e_sigor || item.relation)
                        tratamentos = tratamentos.filter(item => item.e_sigor || item.relation)

                        token = await GetToken({ token: this.props.token })
                        classes = await GetRRClass({ token: token, codigoIbama: resp.residuo_rejeito.sigor_residuo_codigo.residuo_rejeito_codigo, classes: resp.classes })

                        if (resp.residuo_rejeito.sigor_residuo_codigo.sigor_codigo_risco) {
                            codigos_risco = {
                                marNumeroOnu: resp.residuo_rejeito.sigor_residuo_codigo.sigor_codigo_risco.mar_numero_onu,
                                marClasseRisco: resp.residuo_rejeito.sigor_residuo_codigo.sigor_codigo_risco.mar_classe_risco,
                                marNomeEmbarque: resp.residuo_rejeito.sigor_residuo_codigo.sigor_codigo_risco.mar_nome_embarque,
                                greCodigo: resp.residuo_rejeito.sigor_residuo_codigo.sigor_codigo_risco.gre_codigo,
                            }
                        }
                    }

                    this.setState({
                        loading_screen: false, tipo: resp.residuo_rejeito.tipo,
                        nome: resp.residuo_rejeito.nome,
                        classe: resp.residuo_rejeito.classe_id,
                        periculosidade: resp.residuo_rejeito.periculosidade_id,
                        estado: resp.residuo_rejeito.estado_id,
                        acondicionamento: resp.residuo_rejeito.acondicionamento_id,
                        destinacao: resp.residuo_rejeito.destinacao_id,
                        armazenamento: resp.residuo_rejeito.armazenamento_id,
                        tratamento: resp.residuo_rejeito.tratamento_id,
                        descricao: resp.residuo_rejeito.descricao == null ? '' : resp.residuo_rejeito.descricao,
                        tipo_medida_principal: resp.residuo_rejeito.tipo_medida_principal,
                        medida_principal: resp.residuo_rejeito.unidade_principal_id,
                        medida_secundaria: resp.residuo_rejeito.unidade_secundaria_id,
                        tipo_movimentacao_financeira: resp.residuo_rejeito.saldo_financeiro,
                        autorizacao: resp.residuo_rejeito.autorizacao_movimentacao === true ? 'Sim' : 'Não',
                        valor_saldo: parseFloat(resp.residuo_rejeito.valor_saldo_financeiro).toFixed(2),
                        equivalencia: resp.residuo_rejeito.equivalencia,
                        classes: classes,
                        estados: estados,
                        tratamentos: tratamentos,
                        unidades: resp.unidades,

                        danger,
                        periculosidades: resp.periculosidades,
                        acondicionamentos: resp.acondicionamentos,
                        destinacoes: resp.destinacoes,
                        armazenamentos: resp.armazenamentos,
                        sub_materiais: resp.residuo_rejeito.sub_materiais,
                        e_sigor: resp.residuo_rejeito.e_sigor,
                        residuos_selecionados: resp.residuo_rejeito.residuos_sigor,
                        sigorToken: token,
                        ...codigos_risco
                    }, () => { if (resp.residuo_rejeito.e_sigor) this.getAcondicionamentos(resp.residuo_rejeito.estado_fisico.relation ? resp.residuo_rejeito.estado_fisico.relation.sigor_estado_id : resp.residuo_rejeito.estado_id) });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    async getAcondicionamentos(id) {
        let estado = this.state.estados.filter(item => item.id === id)[0]
        let aconds = await GetAcondicionamentos({ token: this.state.sigorToken, estado: estado, acondicionamentos: this.state.acondicionamentos })
        console.log('try', aconds)

        if (aconds.status) {
            this.setState({ acondicionamentosSigor: aconds.aconds })
        } else {
            window.scrollTo(500, 0);
            this.setState({ msgErro: aconds.message, estado: '', acondicionamentosSigor: [] })
        }
    }

    fill_residuos() {
        fetch(`${URL}api/fill_residuos_sigor`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        }).then(async (response) => {
            const resp = await response.json()
            this.setState({ residuos: resp.residuos, residuo_id: resp.residuos[0].id })
        })
    }

    add_residuo(id) {
        if (!this.state.residuos_selecionados.filter(item => item.dados_residuo.id === id)[0]) {
            const residuo = this.state.residuos.filter(item => item.id === id)[0]

            const obj_residuo = {
                id: residuo.id,
                nome: residuo.nome,
                tipo: residuo.tipo
            }

            this.setState({ residuos_selecionados: [...this.state.residuos_selecionados, { dados_residuo: obj_residuo }] })
        }
    }

    remove_residuo(id) {
        const residuos_selecionados = this.state.residuos_selecionados.filter(item => item.dados_residuo.id !== id)
        this.setState({ residuos_selecionados })
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    filterUnits(filterOptions) {
        return this.state.unidades.filter(item => filterOptions.includes(item.tipo));
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect === true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar resíduo/rejeito</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um resíduo/rejeito do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen === false && <div>
                                {this.state.msgErro !== '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do resíduo/rejeito" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} disabled={this.state.e_sigor} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tipo: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um tipo</option>
                                                    <option value={'Resíduo'}>Resíduo</option>
                                                    <option value={'Rejeito'}>Rejeito</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Classe <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.classe} className="form-control" id="cameras" onChange={(e) => {
                                                    if (!this.state.danger && this.state.e_sigor) {
                                                        let danger = VerifySigor({ validation: 'danger', state: this.state })
                                                        this.setState({ classe: e.target.value, danger: danger.status })
                                                    } else {
                                                        // let danger = VerifySigor({ validation: 'danger', state: this.state })
                                                        // console.log('teste classe', danger)
                                                        this.setState({ classe: e.target.value })
                                                    }
                                                }}>
                                                    <option value={''}>Selecione uma classe</option>

                                                    {this.state.classes.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        {this.state.danger &&
                                            <>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">marNumeroOnu <b style={{ color: 'red' }}>*</b>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input type='number' className="form-control" placeholder="Número ONU" onChange={(e) => this.setState({ marNumeroOnu: e.target.value })} value={this.state.marNumeroOnu} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">marClasseRisco <b style={{ color: 'red' }}>*</b>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input className="form-control" placeholder="Classe de risco" type='number' onChange={(e) => this.setState({ marClasseRisco: e.target.value })} value={this.state.marClasseRisco} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">marNomeEmbarque <b style={{ color: 'red' }}>*</b>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input className="form-control" placeholder="Nome do embarque" type="text" onChange={(e) => this.setState({ marNomeEmbarque: e.target.value })} value={this.state.marNomeEmbarque} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-12 d-flex col-form-label">greCodigo <b className='ms-1' style={{ color: 'red' }}>*</b>

                                                        <Tooltip title="Este campo tem que ser preenchido com valores de 1 a 4" placement='right' arrow open={this.state.openInfo}>
                                                            <IconButton size='small'>
                                                                <BsFillInfoCircleFill />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </label>

                                                    <div className="col-sm-12">
                                                        <input className="form-control" placeholder="gre Codigo" type='number' onChange={(e) => {
                                                            if ((e.target.value > 0 && e.target.value <= 4) || e.target.value === '') {
                                                                this.setState({ greCodigo: e.target.value })
                                                                this.setState({ openInfo: false })
                                                            } else {
                                                                this.setState({ openInfo: true })
                                                            }
                                                        }} value={this.state.greCodigo} />
                                                    </div>
                                                </div>
                                            </>}

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Periculosidade </label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.periculosidade} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ periculosidade: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma periculosidade</option>
                                                    {this.state.periculosidades.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Estado físico <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.estado} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ estado: e.target.value }, async () => {
                                                        if (this.state.e_sigor) {
                                                            let estado = this.state.estados.filter(item => item.id === e.target.value)[0]
                                                            this.getAcondicionamentos(estado.relation ? estado.relation.sigor_estado_id : estado.id)
                                                        }
                                                    })
                                                }}>
                                                    <option value={''}>Selecione um estado físico</option>
                                                    {this.state.estados.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Acondicionamento <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.acondicionamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ acondicionamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um acondicionamento</option>
                                                    {this.state.e_sigor ?
                                                        this.state.acondicionamentosSigor.map((item, id) => {
                                                            let disabled = false
                                                            let bgColor = ''
                                                            let color = ''

                                                            if (item.hasPendding) {
                                                                bgColor = '#f44336'
                                                                color = '#ffffff'
                                                                disabled = true
                                                            }
                                                            return (
                                                                <option key={id} style={{ backgroundColor: bgColor, color: color }} disabled={disabled} value={item.id}>[ {item.codigo} ]
                                                                    {item.descricao}
                                                                    {item.hasPendding && '  -  Necessita aprovação entre pendentes'}</option>
                                                            )
                                                        }) :
                                                        this.state.acondicionamentos.map((item, id) => (
                                                            <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao} </option>))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destinação/Disposição <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.destinacao} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ destinacao: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma destinação</option>
                                                    {this.state.destinacoes.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Armazenamento temporário <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.armazenamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ armazenamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um armazenamento temporário</option>
                                                    {this.state.armazenamentos.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tratamento <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tratamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tratamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um tratamento</option>
                                                    {this.state.tratamentos.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Sub-material </label>
                                            <div className="input-group col-sm-12">
                                                <input type="text" className="form-control" onChange={(e) => this.setState({ submaterial: e.target.value })} value={this.state.submaterial} placeholder="Sub-material" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                <button className="btn btn-outline-success" type="button" id="button-addon2" onClick={() => {
                                                    if (this.state.submaterial != '') {
                                                        let sub_material = this.state.sub_materiais;
                                                        sub_material.push({ "nome": this.state.submaterial });
                                                        console.log(sub_material);
                                                        this.setState({
                                                            sub_materiais: sub_material,
                                                            submaterial: '',
                                                        });
                                                    }
                                                }} ><MdAdd /> </button>
                                            </div>
                                        </div>
                                        {this.state.sub_materiais.length != 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Sub materiais selecionados:</label>

                                                {this.state.sub_materiais.map((item, index) => (
                                                    <div key={index} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        border: '1px solid gray',
                                                        borderRadius: 10,
                                                        marginLeft: 10,
                                                        marginTop: 10,
                                                        marginTop: 5
                                                    }}>
                                                        <span style={{ width: '120px' }} >{item.nome}</span>
                                                        <button onClick={() => {
                                                            let submateriais_selecionados = this.state.sub_materiais;
                                                            delete (submateriais_selecionados[index]);
                                                            this.setState({ sub_materiais: submateriais_selecionados });
                                                        }}
                                                            className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', marginTop: '4px', border: '0px solid red' }}><FaTrash color='red' /></button>
                                                    </div>
                                                ))}

                                            </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição do resíduo/rejeito" type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao} disabled={this.state.e_sigor}></textarea>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Tipo da medida principal <b style={{ color: 'red' }}>*</b></label>

                                                        <select value={this.state.tipo_medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ tipo_medida_principal: e.target.value, medida_principal: '', medida_secundaria: '' })
                                                        }}>
                                                            <option value={''}>Selecione um tipo para a medida principal</option>
                                                            <option value={'Peso'}>Peso</option>
                                                            <option value={'Volume'}>Volume</option>
                                                            <option value={'Unidade'}>Unidade</option>


                                                        </select>
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Medida principal <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ medida_principal: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione a medida principal</option>

                                                            {this.filterUnits([this.state.tipo_medida_principal]).map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}

                                                        </select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Em {this.state.tipo_medida_principal === 'Peso' ? 'volume' : 'peso'}, corresponde à (equivalência): <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={3}
                                                            decimalSeparator=","
                                                            valueIsNumericString
                                                            placeholder={"Equivalência em " + (this.state.tipo_medida_principal === 'Peso' ? 'volume' : 'peso')}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ equivalencia: values.value })
                                                            }}
                                                            value={this.state.equivalencia} />
                                                    </div>
                                                    <div className="col-md-6 col-12" style={{ alignSelf: 'flex-end' }}>
                                                        <label className="col-form-label">Medida secundária ({this.state.tipo_medida_principal === 'Peso' ? 'volume' : 'peso'}): <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.medida_secundaria} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ medida_secundaria: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione a medida secundária</option>

                                                            {this.filterUnits(this.state.tipo_medida_principal === 'Peso' ? ['Volume', 'Unidade'] : 'Peso').map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Requer autorização de movimentação? <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.autorizacao} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ autorizacao: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione</option>
                                                    <option value={'Sim'}>Sim</option>
                                                    <option value={'Não'}>Não</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo de movimentação financeira <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo_movimentacao_financeira} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tipo_movimentacao_financeira: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione o tipo de movimentação financeira</option>
                                                    <option value={'Receita'}>Receita</option>
                                                    <option value={'Despesa'}>Despesa</option>
                                                    <option value={'Despesa Evitada'}>Despesa Evitada</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Valor de saldo financeiro estimado <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <NumericFormat className="form-control"
                                                    placeholder="Valor de saldo financeiro estimado" type="text"
                                                    prefix={'R$ '}
                                                    thousandsGroupStyle='thousand'
                                                    thousandSeparator="."
                                                    decimalScale={2}
                                                    decimalSeparator=","
                                                    valueIsNumericString
                                                    onValueChange={(values, e) =>
                                                        this.setState({ valor_saldo: values.value })}
                                                    value={this.state.valor_saldo} />
                                            </div>
                                        </div>

                                        {this.state.e_sigor && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Residuos/Rejeitos Relacionados:</label>
                                            <div className="input-group col-sm-12">
                                                <select value={this.state.residuo_id} className="form-control" id="cameras" onChange={(e) => this.setState({ residuo_id: e.target.value })}>
                                                    {this.state.residuos.map(item => (
                                                        <option key={item.id} value={item.id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                                <button className='btn btn-primary' onClick={() => this.add_residuo(this.state.residuo_id)}>
                                                    <BsPlusLg />
                                                </button>
                                            </div>
                                        </div>}

                                        {this.state.residuos_selecionados.length > 0 &&
                                            <div className="row my-3">
                                                <div className="col-12">
                                                    <table className='table table-striped table-hover text-center'>
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>Tipo</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.residuos_selecionados.map(item2 => (
                                                                <tr key={item2.dados_residuo.id}>
                                                                    <td>{item2.dados_residuo.nome}</td>
                                                                    <td>{item2.dados_residuo.tipo}</td>
                                                                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                                                                        onClick={() => this.remove_residuo(item2.dados_residuo.id)}>
                                                                        <FaTrash color='white' />
                                                                    </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }



                                        {this.state.loading_save === false && <div className="row mt-3">
                                            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <Link to="/residuos_rejeitos"> <span
                                                    className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                            </div>
                                            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <button type="button" onClick={() => {
                                                    if (this.state.e_sigor) {
                                                        let save = VerifySigor({ validation: 'danger', state: this.state })
                                                        if (save.status) {
                                                            this.salvar()
                                                        }
                                                    } else {
                                                        this.salvar()
                                                    }

                                                }} className="btn btn-success btn-lg waves-effect waves-light"
                                                    style={{ backgorundColor: '#f2f2f2' }}>Salvar resíduo/rejeito</button>
                                            </div>
                                        </div>}
                                        {this.state.loading_save === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div></div>}

                                        {/* {this.state.andamento === true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                                    </div>




                                </div>

                            </div>}
                            {this.state.loading_screen === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                        </div>
                    </div>
                </div>
            </div >


        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarRR);


