import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fade, IconButton, ThemeProvider } from '@mui/material';
import { MdAdd, MdClose } from 'react-icons/md';
import Theme from '../../Theme';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '.4rem',
  p: 4,
};

const CargaModal = ({ state, setState, item }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Function where it increments and decrements the waste lists of each driver
  function addCarga(motoristaId, residuoId) {
    //Choice of map type function to manipulate the array of vehicles and drivers adding new objects to each item
    let veiculos_motoristas = state.veiculos_motoristas.map(item => {
      if (item.motorista.id === motoristaId) {
        //gathering the necessary information with spread and filter
        let residuo
        let capacidade = parseFloat(item.veiculo.capacidade_maxima_peso)
        let codigos = [...state.sigorCodigos]
        console.log('codigos', codigos)
        let checked = item.cargas.filter(item => item.residuo_rejeito_id === residuoId)[0]
        //If the available capacity value has not been incremented, the vehicle's maximum capacity value is used.
        if (item.veiculo.capacidade_disponivel || item.veiculo.capacidade_disponivel === 0) {
          capacidade = item.veiculo.capacidade_disponivel * item.veiculo.unidade_peso.equivalencia
        }
        console.log('veiculo', item.veiculo)
        //If the value of the checkbox of the respective residue is false, it is a sign that the user is checking the item in the list                
        if (!checked.checked) {
          //Setting the check value to true
          checked.checked = true
          //Going through each item of the residues added in the dispatch
          codigos.map(item2 => {
            if (item2.residuo_id === residuoId && !item.isFull) {
              //When finding the residue on the list, the value of the vehicle capacity is compared with the outstanding quantity of the residue
              if (item2.pesoKg >= capacidade) {
                //If you have more pending weight than capacity, the following calculations are made:
                item2.quantidade = capacidade / item2.equivalencia
                item2.quantidadeKg = capacidade
                item2.marQuantidade = item2.marQuantidade - (capacidade / item2.equivalencia)
                item2.pesoKg = item2.pesoKg - capacidade

                item.veiculo.capacidade_disponivel = 0
                item.isFull = true
              } else {
                //If there is more weight space in the vehicle than the hanging weight of the residue, the following calculations are made
                item2.quantidade = item2.pesoKg / item2.equivalencia
                item2.quantidadeKg = item2.pesoKg
                item.veiculo.capacidade_disponivel = capacidade - item2.pesoKg
                item2.marQuantidade = 0
                item2.pesoKg = 0
              }
              residuo = { ...item2 }
              residuo.marQuantidade = item2.quantidade
            }
            return item2
          })
        } else {
          //When the user deselects the option from the list, calculations will be made to decrease the values
          checked.checked = false
          let residuo = item.sigor.filter(item => item.residuo_id === residuoId)[0]
          let newList = item.sigor.filter(item => item.residuo_id !== residuoId)
          //Going through each item of the residues added in the dispatch
          codigos.map(item2 => {
            if (item2.residuo_id === residuoId) {
              item2.marQuantidade += residuo.marQuantidade
              item.veiculo.capacidade_disponivel = capacidade + residuo.quantidadeKg
              item2.pesoKg += residuo.quantidadeKg
              item2.quantidade -= residuo.quantidade
              item.isFull = false
            }
            return item2
          })
          item.sigor = newList
        }
        //If the residue added already belongs to the list, it is only updated, if it does not belong, it is added
        if (residuo) {
          if (item.sigor) item = { ...item, sigor: [...item.sigor, { ...residuo }] };
          else item = { ...item, sigor: [{ ...residuo }] }
        }
      }
      console.log('item', item)
      return item
    })
    setState({ veiculos_motoristas })
  }

  return (
    <>
      <button className="btn btn-primary ms-1 btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid green', zIndex: '2' }} onClick={handleOpen}>
        <MdAdd color='green' ></MdAdd>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Fade in={open}>
            <Box sx={style}>
              {!state.loading_save ? (
                <>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className="d-flex">
                      <div className="mt-2">
                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>
                          Controle de Carga
                        </h4>
                      </div>
                      <div className="ms-auto">
                        <IconButton onClick={handleClose}>
                          <MdClose />
                        </IconButton>
                      </div>
                    </div>
                    <div className="text-center">
                      <p style={{ fontSize: '1rem', color: 'black' }}>
                        Gerencie as cargas pendentes da destinação
                      </p>
                    </div>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className='mt-4'>
                      <div className="d-flex align-items-center">
                        <Typography className='me-2' variant='subtitle1' sx={{ fontWeight: 'bold' }}>Motorista: </Typography>
                        <Typography variant='body1'>{item.motorista.nome}</Typography>
                      </div>

                      <div className="d-flex align-items-center mb-4">
                        <Typography className='me-2' variant='subtitle1' sx={{ fontWeight: 'bold' }}>Veículo: </Typography>
                        <Typography variant='body1'>{item.veiculo.modelo.nome}</Typography>
                      </div>
                      {item.cargas.map(item2 => {
                        let residuo = state.sigorCodigos.filter(item => item.residuo_id === item2.residuo_rejeito_id)[0]
                        let hasItem = item.sigor ? item.sigor.filter(item => item.residuo_id === residuo.residuo_id)[0] : false

                        return (
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" disabled={(residuo.marQuantidade === 0 && !hasItem) ? true : false || (!hasItem && item?.isFull)} onChange={() => {
                              addCarga(item.motorista.id, item2.residuo_rejeito_id)
                            }} checked={item2.checked} id="flexCheckDefault" />
                            <label className="form-check-label me-2" htmlFor="flexCheckDefault">
                              {item2.nome}
                            </label>
                          </div>
                        )
                      })
                      }
                      {item?.isFull && <Typography className='mt-3'>Veículo sem capacidade de carga</Typography>}
                    </div>
                  </Typography>
                </>
              ) : (
                <div className="row">
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </Box>
          </Fade>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default CargaModal;
