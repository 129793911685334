import React from 'react'
import Veiculos from './Veiculos';
import Filter from '../../../Auxiliar/Filter';
import Compliances from '../Compliance/Compliances';

const SelecionarVeiculos = () => {
  const [search, setSearch] = React.useState(false)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState({
    veiculos: { value: true, label: 'Veículos', checked: true },
    compliances: { value: false, label: 'Compliances', checked: false },
  })

  return (
    <div className="card">
      <div className="card-body" id="card">
        <div className="d-flex align-items-start">
          <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Veículos</h4>
          <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
        </div>
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os veículos do sistema</p>
        </div>
        <div className="row">
          {options.veiculos.value &&
            <Veiculos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.compliances.value &&
            <Compliances dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} owner={'veiculos'} searchFor='vehicle_id' />}
        </div>
      </div>
    </div>
  )
}

export default SelecionarVeiculos