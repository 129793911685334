import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Divider, IconButton, Pagination } from "@mui/material";
import { URL, URLopen } from "../variables";
import Notificacao from "./Notificacao";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { MdMoreVert } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { notificationCounter } from "../actions/AppActions";

function Notificacoes() {
  const reducer = useSelector((store) => store.AppReducer);
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const toggle = useCallback(() => setMenu(!menu), [menu]);

  const [state, setState] = useState({
    loading: true,
    warning: false,
    notificationContent: [],
    page: 1,
    totalPages: 1,
  });

  useEffect(() => {
    getNotifications(1);
  }, [reducer.notifications]);

  function getNotifications(page) {
    fetch(
      `${URL}api/get_blueen_open_notifications`,
      {
        method: "POST",
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${reducer.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: page,
          url_back: URL,
        })
      }
    )
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({
          ...state,
          loading: false,
          notificationContent: resp.notifications,
          page: resp.pagination.current_page,
          totalPages: resp.pagination.total_pages,
        });
      })
      .catch((err) => console.log(err));
  }

  function markAsRead() {
    fetch(`${URLopen}api/auth/mark_as_read`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${reducer.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: URL,
        isOpen: false,
      }),
    })
      .then(async () => {
        getNotifications(1);
        dispatch(notificationCounter(0));
      })
      .catch((err) => console.log(err));
  }

  const handleChangePage = (event, page) => {
    if (page != state.page) {
      setState({ ...state, page: page });
      getNotifications(page);
    }
  };

  return (
    <div className="container">
      <header className="d-flex justify-content-between align-items-center">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Notificações
        </h4>
        <Dropdown isOpen={menu} toggle={toggle} direction="left">
          <DropdownToggle className="btn" tag="button">
            <IconButton size="small" color="inherit">
              <MdMoreVert />
            </IconButton>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => markAsRead()}>
              <BsCheck /> Marcar todas como lidas
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </header>

      {state.loading && (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div className="row d-flex align-items-center justify-content-center">
          {state.notificationContent.length > 0 && (
            <div>
              <div className="card">
                {state.notificationContent.map((item, id) => (
                  <React.Fragment key={item.id}>
                    <Notificacao
                      state={state}
                      setState={setState}
                      reducer={reducer}
                      item={item}
                    />
                    {id !== state.notificationContent.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </div>
              <div className="d-flex justify-content-end my-2">
                <Pagination
                  count={state.totalPages}
                  page={state.page}
                  onChange={handleChangePage}
                  defaultPage={state.page}
                  color="primary"
                />
              </div>
            </div>
          )}
          {state.notificationContent.length == 0 && (
            <Alert severity="info">Nenhuma notificação encontrada.</Alert>
          )}
        </div>
      )}
    </div>
  );
}

export default Notificacoes;
