import { URL } from '../../variables'

async function GetToken(props) {
  return fetch(`${URL}api/get_empresa/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Authorization': `Bearer ${props.token}`,
    }
  }).then(async (response) => {
    const resp = await response.json()
    if (resp.empresa.sigor_gerador) {
      return getSigorToken(resp.empresa.sigor_gerador)
    } else {
      return { status: false, message: 'Empresa não possui dados Sigor cadastrados' }
    }
  })
}

async function getSigorToken({ cnpjSigor, unidade, senha }) {
  return fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/gettoken`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "cpfCnpj": cnpjSigor,
      "senha": senha,
      "unidade": unidade
    }
    )
  }).then(async (response) => {
    const resp = await response.json()
    return resp.objetoResposta
  })
}

export default GetToken