import { formatDate } from "@formatjs/intl";
import { CircularProgress } from "@mui/material";
import React, { Component } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { MdDelete, MdModeEdit, MdSearch, MdVisibility } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";
import { maskDate } from "../../../Auxiliar/Masks";
import { GET } from "../../../Auxiliar/Requests";
import { URL, URLopen } from "../../../variables";

class Agendamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true,
      path: "/agendamentos",
      loading_screen: false, //alterar para true
      page: 1,
      total_pages: 1,
      msgErro: "",
      search: "",
      dateOf: "",
      dateFor: "",
      reload: false,
      related: [],
      active_loading: [],
      status: [],
      collect_solicitations: []
    };
    this.timeout = null
  }

  componentDidMount() {
    this.getData(this.state.page)
  }

  componentDidUpdate() {
    if (this.props.allow) this.getData(this.state.page)
  }

  async getData(page) {
    this.props.setAllow(false)
    this.setState({ loading: true })

    const status = this.props.status ? this.props.status.label : ''
    const response = await GET({
      url: `auth/get_solicitations?search=${this.state.search}&status=${status}&dateFor=${this.props.dateFor}
      &dateOf=${this.props.dateOf}&url_back=${URL}&page=${page}`, blueenOpen: true
    })
    console.log('response', response)

    this.setState({ collect_solicitations: response.collect_solicitations, total_pages: response.pagination.last_page, loading: false })
  }

  handleSearch({ value }) {
    clearTimeout(this.timeout);
    this.setState({ search: value })
    this.timeout = setTimeout(() => this.props.setAllow(true), 750);
  }

  render() {
    return (
      <>
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div>
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <form
                className="app-search mt-1"
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  clearTimeout(this.pesquisarTimeout);
                  this.setState({ page: 1 });
                  this.get_relationships(1, this.state.search, true);
                  e.preventDefault();
                }}
              >
                <div
                  className="form-group mb-0"
                  style={{ width: "100%", position: "relative" }}
                >
                  <input
                    name="search"
                    placeholder="Pesquisar pelo nome ou status..."
                    className="form-control"
                    value={this.state.search}
                    style={{
                      width: "100%",
                      paddingRight: 40,
                      borderRadius: 100,
                    }}
                    onChange={({ target }) => this.handleSearch(target)}
                  />
                  <div
                    style={{
                      width: 40,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdSearch />
                  </div>
                </div>
              </form>
            </div>

            {/* <div className="col-12 col-md-6 d-flex justify-content-end">
                <Link to="/relacionamentos_externos/convidar">
                  <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                    Convidar terceiro
                  </span>
                </Link>
              </div> */}
          </div>
          <div className="table-rep-plugin">
            <div
              // className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              {!this.state.loading ?
                <table
                  id="tech-companies-1"
                  className="table table-striped table-hover"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Responsável</th>
                      <th>Destino</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {/* dados da lista ----------------------------------- */}
                  <tbody>
                    {this.state.collect_solicitations.map(item => (
                      <tr>
                        <td>{item.collect_date?maskDate(item.collect_date):'-'}</td>
                        <td>{item.responsible.name}</td>
                        <td>{item.destination.commercial_name}</td>
                        <td>{item.status}</td>
                        <td>
                          <Link to={`/agendamentos/view/${item.id}`}>
                            <span
                              className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                              data-tip="Ver"
                            >
                              <Tooltip />
                              <MdVisibility style={{ color: "white" }} />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className="d-flex justify-content-center my-3">
                  <div className="spinner-border text-primary" role="status"></div>
                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próxima"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                initialPage={this.state.page - 1}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page.selected + 1 });
      this.getData(page.selected + 1)
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(Agendamentos);
