import React from 'react';
import Classes from './Classes';
import ClassesPendentes from './ClassesSigor';
import Filter from '../../../Auxiliar/Filter';
import { useSelector } from 'react-redux';


const SelecionarJsClasse = () => {
    const permission = useSelector(state => state.AppReducer.permissoes.sigor.acesso)

    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        classe: { value: true, label: 'Classes Finalizadas', checked: true },
        classePendente: { value: false, label: 'Classes Pendentes(SIGOR)', checked: false, disabled: !permission },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Classes</h4>
                    <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados as classes do sistema</p>
                </div>
                <div className="row">
                    {options.classe.value &&
                        <Classes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.classePendente.value &&
                        <ClassesPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsClasse