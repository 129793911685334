
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';

// users
import { URL } from '../../../variables';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import { MdPictureAsPdf, MdReply } from 'react-icons/md';
import { BsFilePdfFill } from 'react-icons/bs';
import { CircularProgress } from '@mui/material';
import { ToastContent, renderToast } from '../../../Auxiliar/Alerts';

class ViewDespachoFinalizada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/despacho',
            loading_screen: false,
            ResiduosRejeitos: [],
            Solicitacoes: [],
            solicitacoes_selecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],

            Materialselecionado: {},
            SolicitacaoSelecionada: {},
            Estoque: '',
            TransportadoraId: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            DataFinalizado: '',
            DataCriacao: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            PesoTotalVeiculo: '0',
            VolumeTotalVeiculo: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            veiculos_motoristas: [],
            despacho: {},

            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
        };
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_view_despacho_finalizada/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let PesoTotalVeiculo = 0;
                    let VolumeTotalVeiculo = 0;

                    for (let i = 0; i < resp.veiculos_despacho.length; i++) {
                        let veiculo = resp.veiculos_despacho[i];
                        PesoTotalVeiculo = parseFloat(PesoTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_peso) * parseFloat(veiculo.unidade_peso.equivalencia));
                        VolumeTotalVeiculo = parseFloat(VolumeTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_volume) * parseFloat(veiculo.unidade_volume.equivalencia));

                    }
                    this.setState({
                        LoadingLabels: resp.despacho_finalizado?.materiais_coletados.map(item => ({ id: item.id, loading: false })),
                        loading_screen: false,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,
                        PesoTotalVeiculo,
                        VolumeTotalVeiculo,
                        // TransportadoraId: resp.despacho_finalizado.transportadora.nome_fantasia,
                        TransportadoraId: resp.despacho_finalizado.transportadora == null ? 'Sem transportadora' : resp.despacho_finalizado.transportadora.nome_fantasia,

                        Situacao: resp.despacho_finalizado.situacao,
                        Descricao: resp.despacho_finalizado.descricao != null ? resp.despacho_finalizado.descricao : '',
                        Solicitacao: resp.despacho_finalizado.solicitacao_id,
                        Estoque: resp.despacho_finalizado.estoque.nome_fantasia,
                        DataSolicitacao: resp.despacho_finalizado.data_solicitacao,
                        DataAgendamento: resp.despacho_finalizado.data_agendamento != null ? resp.despacho_finalizado.data_agendamento : '',
                        DataInicio: resp.despacho_finalizado.data_inicio != null ? resp.despacho_finalizado.data_inicio : 'Finalizado sem iniciar',
                        PesoTotal: resp.despacho_finalizado.peso_total,
                        VolumeTotal: resp.despacho_finalizado.volume_total,
                        ReceitaTotal: resp.despacho_finalizado.receita_total,
                        DespesaTotal: resp.despacho_finalizado.despesa_total,
                        Saldo: resp.despacho_finalizado.saldo,
                        MateriaisInseridos: resp.despacho_finalizado.materiais,

                        veiculos_motoristas: resp.despacho_finalizado.veiculos_motoristas,
                        // SolicitacaoSelecionada: [resp.despacho.solicitacao_coleta],
                        solicitacoes_selecionadas: resp.solicitacoes_selecionadas,
                        // SolicitacaoSelecionada: [resp.despacho.solicitacao_coleta],
                        DataCriacao: resp.despacho_finalizado.data_criacao,
                        despacho: resp.despacho_finalizado,

                        ChaveNota: resp.despacho_finalizado.chave_nota,
                        NumeroNota: resp.despacho_finalizado.numero_nota,
                        SerieNota: resp.despacho_finalizado.serie_nota,

                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }



    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }

    generatePdf = (id) => {
        this.setState({
            LoadingLabels: this.state.LoadingLabels.map(item => {
                if (item.id === id) item.loading = true
                return item
            })
        })
        const response = fetch(`${URL}api/generate-label/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.token}`
            },
            responseType: 'blob' // Adicione este parâmetro
        }).then(async (response) => {
            if (response.ok) {
                const blob = await response.blob()
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.target = '_blank'
                link.click()
            } else {
                const json = await response.json()
                renderToast({ type: 'error', error: json.message })
            }

            this.setState({
                LoadingLabels: this.state.LoadingLabels.map(item => {
                    if (item.id === id) item.loading = false
                    return item
                })
            })

            return response // Retorne a resposta original (opcional)
        })
    }

    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Despacho</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Despacho finalizado</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Estoque destino: <span style={{ fontWeight: 'normal' }}>{this.state.Estoque}</span></label>
                                        </div>

                                        <hr />
                                        {<div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dados das solicitações em aberto:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Data da solicitação</th>
                                                        <th>Ponto de coleta</th>
                                                        <th>Responsável</th>
                                                        <th>Código da solicitação</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.solicitacoes_selecionadas.length == 0 && <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>Nenhuma solicitação selecionada</td>
                                                    </tr>}
                                                    {this.state.solicitacoes_selecionadas.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</td>
                                                            <td>{item.ponto_coleta.nome_fantasia}</td>

                                                            <td>{item.responsavel.name}</td>
                                                            <td>{item.codigo_solicitacao}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th>Ponto Coleta</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{item.ponto_name}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        {this.state.despacho?.materiais_coletados?.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais coletados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th>Ponto Coleta</th>
                                                            <th>Status</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.despacho.materiais_coletados.map((item) => (
                                                            <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{item.ponto_name}</td>
                                                                <td>{item.status}</td>
                                                                <td>
                                                                    <span className="btn btn-warning btn-outline btn-circle me-1 mb-1" data-tip="Gerar etiqueta" onClick={() => this.generatePdf(item.id)}><Tooltip />
                                                                        {this.state.LoadingLabels.filter(item2 => item2.id === item.id)[0].loading ? <CircularProgress className='text-white' sx={{ padding: 0 }} size={16} /> : <BsFilePdfFill style={{ color: 'white' }} />}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {console.log('item rr', item)}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Agendado para: </label>{this.state.DataAgendamento.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Iniciado em: </label>{this.state.DataInicio.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Finalizado em: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> {maskPrice(this.state.ReceitaTotal)}</div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> {maskPrice(this.state.DespesaTotal)}</div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.replace('.', ',')}</div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Transportadora: <span style={{ fontWeight: 'normal' }}>{this.state.TransportadoraId}</span></label>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Chave da NF-e: <span style={{ fontWeight: 'normal' }}>{this.state.ChaveNota ?? '-'}</span></label>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Número da NF-e: <span style={{ fontWeight: 'normal' }}>{this.state.NumeroNota ?? '-'}</span></label>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Série da NF-e: <span style={{ fontWeight: 'normal' }}>{this.state.SerieNota ?? '-'}</span></label>
                                                </div>
                                            </div>



                                        </div>
                                        {<div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Veículo</th>
                                                        <th>Motorista</th>
                                                        <th>Capacidade Peso</th>
                                                        <th>Capacidade Volume</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.veiculos_motoristas.length == 0 && <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                                                    </tr>}
                                                    {this.state.veiculos_motoristas.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.veiculo.modelo.nome}</td>
                                                            <td>{item.motorista.nome}</td>

                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)}  {item.veiculo.unidade_peso.sigla}</td>
                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)}  {item.veiculo.unidade_volume.sigla}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.PesoTotalVeiculo).toFixed(0))} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.VolumeTotalVeiculo).toFixed(0))} L</div>
                                        </div>
                                        {this.state.Descricao != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações:  <span style={{ fontWeight: 'normal' }}>{this.state.Descricao}</span> </label>
                                        </div>}


                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link onClick={() => { this.props.history.goBack(); }}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                        {this.state.despacho.rotas_path != null && <a target="_blank" href={URL + 'storage/' + this.state.despacho.rotas_path} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Visualizar Rota </a>}
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                        </div>
                        <ToastContent />
                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewDespachoFinalizada);


