
import React from 'react';
import DespachosAbertos from './DespachosAbertos';
import DespachoFinalizada from '../Despacho/DespachoFinalizada';
import ColetaRecebida from './ColetaRecebida';
import Filter from '../../../Auxiliar/Filter';

const SelecionarJsColetaRecebida = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        despachoAberto: { value: true, label: 'Coletas Pendentes', checked: true },
        coletaRecebida: { value: false, label: 'Coletas Recebidas', checked: false },
        despachoFinalizado: { value: false, label: 'Despachos Finalizados', checked: false },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Receber Coletas</h4>
                    <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listadas as coletas do sistema</p>
                </div>
                <div className="row">
                    {options.despachoAberto.value &&
                        <DespachosAbertos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.coletaRecebida.value &&
                        <ColetaRecebida dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.despachoFinalizado.value &&
                        <DespachoFinalizada dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsColetaRecebida
