import React from 'react';
import EstadosFisicos from './EstadosFisicos';
import EstadosFisicosPendentes from './EstadosFisicosSigor';
import Filter from '../../../Auxiliar/Filter';
import { useSelector } from 'react-redux';


const SelecionarJsEstadoFisico = () => {
    const permission = useSelector(state => state.AppReducer.permissoes.sigor.acesso)

    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        estadoFisico: { value: true, label: 'Estados Fisicos Finalizados', checked: true },
        estadoFisicoPendente: { value: false, label: 'Estados Fisicos Pendentes(SIGOR)', checked: false, disabled: !permission },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Estados Fisicos</h4>
                    <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os Estados Fisicos do sistema</p>
                </div>
                <div className="row">
                    {options.estadoFisico.value &&
                        <EstadosFisicos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.estadoFisicoPendente.value &&
                        <EstadosFisicosPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsEstadoFisico