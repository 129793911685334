
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { MdDelete, MdModeEdit, MdSearch, MdVisibility } from 'react-icons/md';
import Tooltip from 'react-tooltip';
// users
import moment from 'moment';
import { URL } from '../../../variables';
import { maskCep, maskCnpj, maskNumber, maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';

class ViewSolicitacaoColetaFinalizada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/solicitar_coleta',
            loading_screen: false,

            PontosColetas: [],
            Estoque: [],
            Materiais: [],
            Responsaveis: [],
            MateriaisSelecionados: [],
            MateriaisIseridos: [],
            MaterialSelecionado: {},
            EstoqueSelecionado: {},
            Devolucao: [],
            Devolucao_selecionada: {},
            Despachos: [],
            Despachos_finalizados: [],

            Descricao: '',
            UnidadePeso: '',
            UnidadeVolume: '',
            Material: '',
            Peso: '',
            Volume: '',
            Peso_kg: '',
            Volume_lt: '',
            NomeMaterial: '',
            PontoColeta: '',
            DataAtual: '',
            DataSolicitacao: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            validated: false,
            required: false,
            CodigoSolicitacao: '',

        };
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_view_solicitar_coleta_finalizada/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let residuo_rejeito = resp.solicitacoes_edit.material;
                    let old_material = [];
                    for (let i = 0; i < residuo_rejeito.length; i++) {
                        let novo_material = {
                            'residuo_rejeito_id': residuo_rejeito[i].residuo_rejeito_id, 'nome': residuo_rejeito[i].residuo_rejeito.nome,
                            'unidade_peso': residuo_rejeito[i].residuo_rejeito.unidade_principal.tipo === 'Peso' ? residuo_rejeito[i].residuo_rejeito.unidade_principal.sigla : residuo_rejeito[i].residuo_rejeito.unidade_secundaria.sigla,
                            'unidade_volume': residuo_rejeito[i].residuo_rejeito.unidade_principal.tipo !== 'Peso' ? residuo_rejeito[i].residuo_rejeito.unidade_principal.sigla : residuo_rejeito[i].residuo_rejeito.unidade_secundaria.sigla,
                            'peso': residuo_rejeito[i].peso, 'volume': residuo_rejeito[i].volume, 'peso_solicitado': residuo_rejeito[i].peso_solicitado, 'volume_solicitado': residuo_rejeito[i].volume_solicitado, 'peso_kg': residuo_rejeito[i].peso_kg, 'volume_lt': residuo_rejeito[i].volume_lt
                        };
                        old_material = [...old_material];
                        old_material.push(novo_material);
                    }
                    console.log(old_material);


                    let pontos_estoque_sel = resp.estoque.filter(item => item.ponto_coleta_id == resp.solicitacoes_edit.ponto_coleta_id);
                    let ponto_verif = pontos_estoque_sel.filter(item => item.peso_total != 0 && item.volume_total != 0);
                    let materiais = ponto_verif.map(item => item.material);
                    this.setState({
                        loading_screen: false,
                        PontosColetas: resp.pontos_coleta,
                        Responsaveis: resp.responsaveis,
                        DataSolicitacao: resp.solicitacoes_edit.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1'),
                        PontoColeta: resp.solicitacoes_edit.ponto_coleta.nome_fantasia,
                        Descricao: resp.solicitacoes_edit.descricao,
                        Estoque: resp.estoque,
                        Despachos: resp.despachos,
                        Despachos_finalizados: resp.despachos_finalizados,
                        MateriaisSelecionados: old_material,
                        Materiais: materiais,
                        Responsavel_id: resp.solicitacoes_edit.responsavel.name,
                        CodigoSolicitacao: resp.solicitacoes_edit.codigo_solicitacao

                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
            // this.props.mudarLoadingHome(false);
        });
    }




    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }



    render() {


        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Solicitação de coleta</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>visualização da solicitação de coleta</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Data da solicitação: <span style={{ fontWeight: 'normal' }}>{this.state.DataSolicitacao}</span></label>

                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Ponto de coleta: <span style={{ fontWeight: 'normal' }}> {this.state.PontoColeta}</span></label>
                                        </div>

                                        <div className="form-group row" style={{ marginTop: '30px' }}>
                                            <p className="col-sm-6 "> <b className="col-sm-4 ">Código : </b> {this.state.CodigoSolicitacao} </p>
                                        </div>
                                        {this.state.MateriaisSelecionados.length != 0 && <>
                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }} >Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso solicitado</th>
                                                            <th>Volume solicitado</th>
                                                            <th>Peso coletado</th>
                                                            <th>Volume coletado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisSelecionados.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso_solicitado + '', true)} {item.unidade_peso}</td>
                                                                <td>{maskNumberBr(item.volume_solicitado + '')} {item.unidade_volume}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.unidade_peso}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.unidade_volume}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                            <hr />
                                        </>}
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }} >Despachos Pendentes</label>
                                            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Peso atendido</th>
                                                        <th>Volume atendido</th>
                                                        <th>Data de início</th>
                                                        <th>Status</th>
                                                        <th data-priority="3">Ações</th>
                                                    </tr>
                                                </thead>
                                                {/* dados da lista ----------------------------------- */}
                                                <tbody>
                                                    {this.state.Despachos.map((item, id) => (
                                                        <tr key={id}>
                                                            {console.log(item)}
                                                            <td>{item.codigo}</td>

                                                            <td>{maskNumberBr(item.peso_total + '')} Kg</td>
                                                            <td>{maskNumberBr(item.volume_total + '')} L</td>
                                                            <td>{item.data_inicio != undefined ? item.data_inicio.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1') : 'Não iniciado'}</td>
                                                            <td>{item.situacao.charAt(0).toUpperCase() + item.situacao.toLowerCase().slice(1)}</td>
                                                            {(item.situacao == 'Rascunho' || item.situacao == 'Agendado') && <td>
                                                                {<Link to={'/despacho/editar/interno/' + item.id}><span className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                                    data-tip="Editar"
                                                                ><MdModeEdit /></span></Link>}
                                                                {this.props.user.tipo_usuario.permissoes['realizar_despacho'].exclusao == true && <button onClick={() => { this.setState({ show_warning: true, destinacao_to_delete: item.id }); }}
                                                                    className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1" data-tip="Excluir"><Tooltip /><MdDelete />
                                                                </button>}
                                                            </td>}
                                                            {(item.situacao == 'Iniciado') && <td>
                                                                {<Link to={'/despacho/view/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                    data-tip="Ver"
                                                                ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>}
                                                            </td>}
                                                        </tr>
                                                    ))}

                                                </tbody>

                                            </table>
                                            {this.state.Despachos.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum despacho cadastrado</p>

                                                </div>
                                            </div>}
                                            <hr />
                                        </div>
                                    </div>


                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }} >Despachos Finalizados</label>
                                            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Peso atendido</th>
                                                        <th>Volume atendido</th>
                                                        <th>Data de início</th>
                                                        <th>Status</th>
                                                        <th data-priority="3">Ações</th>
                                                    </tr>
                                                </thead>
                                                {/* dados da lista ----------------------------------- */}
                                                <tbody>
                                                    {this.state.Despachos_finalizados.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.codigo}</td>

                                                            <td>{maskNumberBr(item.peso_total + '')} Kg</td>
                                                            <td>{maskNumberBr(item.volume_total + '')} L</td>
                                                            <td>{item.data_inicio != undefined ? item.data_inicio.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1') : 'Não iniciado'}</td>
                                                            <td>{item.situacao.charAt(0).toUpperCase() + item.situacao.toLowerCase().slice(1)}</td>
                                                            {(item.situacao == 'Rascunho' || item.situacao == 'Agendado') && <td>
                                                                {<Link to={'/despacho/editar/interno/' + item.id}><span className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                                    data-tip="Editar"
                                                                ><MdModeEdit /></span></Link>}
                                                                {<button onClick={() => { this.setState({ show_warning: true, destinacao_to_delete: item.id }); }}
                                                                    className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1" data-tip="Excluir"><Tooltip /><MdDelete />
                                                                </button>}
                                                            </td>}

                                                            {(item.situacao == 'Iniciado') && <td>
                                                                {<Link to={'/despacho/view/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                    data-tip="Ver"
                                                                ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>}
                                                            </td>}
                                                        </tr>
                                                    ))}

                                                </tbody>

                                            </table>
                                            {<div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum despacho cadastrado</p>

                                                </div>
                                            </div>}
                                            <hr />
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição: <span style={{ fontWeight: 'normal' }}> {this.state.Descricao}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Responsável: <span style={{ fontWeight: 'normal' }}> {this.state.Responsavel_id}</span></label>
                                        </div>

                                    </div>

                                </div>
                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link onClick={() => { this.props.history.goBack(); }}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { logout })(ViewSolicitacaoColetaFinalizada);


