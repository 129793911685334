
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import SweetAlert from 'react-bootstrap-sweetalert';
import jsPDF from 'jspdf';


class ViewDestinacaoColeta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao',
            loading_screen: false,
            ResiduosRejeitos: [],
            Destinos: [],
            DestinoSelecionado: [],
            Solicitacoes: [],
            SolicitacoesSelecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],

            Materialselecionado: {},
            SolicitacaoSelecionada: {},

            TransportadoraNome: '',
            Destino: '',
            Motorista: '',
            Veiculo: '',
            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            TransportadoraId: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            Destino: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            DataFinalizado: '',
            DataCriacao: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,

        };
    }
    // Pdf() {

    //     const doc = new jsPDF();

    //     doc.text('MTR – MANIFESTO PARA TRANSPORTE DE RESÍDUOS/REJEITOS', 15.8, 10)
    //     console.log(this.state.SolicitacaoSelecionada);
    //     let ponto_coleta = this.state.SolicitacaoSelecionada[0].ponto_coleta;
    //     console.log(ponto_coleta);
    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: '1. GERADOR', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Razão social:', texto: ponto_coleta.nome_fantasia },
    //         { titulo: 'Endereço:', texto: ponto_coleta.logradouro + ', ' + ponto_coleta.numero + ' - ' + ponto_coleta.bairro + ', ' + ponto_coleta.cidade + '-' + ponto_coleta.estado },
    //         { titulo: 'Telefone:', texto: ponto_coleta.responsavel.user_dado.telefone },
    //         { titulo: 'E-mail:', texto: ponto_coleta.responsavel.email },
    //         { titulo: 'Responsável:', texto: ponto_coleta.responsavel.name }]
    //     });

    //     var mat_inserido = [];
    //     var mat_data = [];
    //     for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
    //         for (let j = 0; j < this.state.ResiduosRejeitos.length; j++)
    //             if (this.state.ResiduosRejeitos[j].id == this.state.MateriaisInseridos[i].residuo_rejeito_id) {
    //                 mat_data.push(this.state.ResiduosRejeitos[j]);
    //                 j = this.state.ResiduosRejeitos.length;
    //             }
    //     }
    //     if (this.state.MateriaisInseridos.length == 1) {
    //         mat_inserido = [{
    //             name: mat_data[0].nome,
    //             ef: mat_data[0].estado_fisico.descricao,
    //             ac: mat_data[0].acondicionamento.descricao,
    //             cl: mat_data[0].classe.nome,
    //             qt: this.state.MateriaisInseridos[0].peso_kg,
    //             un: 'Kg'
    //         },
    //         { name: 'TOTAL', ef: '', ac: '', cl: '', qt: this.state.PesoTotal, un: 'Kg' },]
    //     } else if (this.state.MateriaisInseridos.length < 1) {
    //         mat_inserido = [{ name: '', id: '' }, { name: '', id: '' },];
    //     } else {
    //         mat_inserido = mat_data.map(item => ({
    //             name: item.nome, ef: item.estado_fisico.descricao, ac: item.acondicionamento.descricao, cl: item.classe.nome,
    //             qt: this.state.MateriaisInseridos.filter(item2 => item2.residuo_rejeito_id == item.id).map(item3 => item3.peso_kg), un: 'Kg'
    //         }));
    //         mat_inserido.push({ name: 'TOTAL', ef: '', ac: '', cl: '', qt: this.state.PesoTotal, un: 'Kg' },)
    //     }

    //     doc.setFillColor(22, 160, 133);
    //     doc.rect(14.12, 72.8, 181.76, 7.5, 'FD'); //Fill and Border
    //     doc.setFontSize(10);
    //     doc.setTextColor('white');
    //     doc.setFont('Helvetica', 'bold')
    //     doc.text('2. DESCRIÇÃO DOS RESÍDUOS/REJEITOS', 16, 77);

    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         startY: 80,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', lineWidth: 0, fillColor: 'gray', lineColor: 'black', },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: 'Nome', dataKey: 'name' },
    //             { header: 'Estado físico', dataKey: 'ef' },
    //             { header: 'Acondicionamento', dataKey: 'ac' },
    //             { header: 'Classe', dataKey: 'cl' },
    //             { header: 'Q.Total', dataKey: 'qt' },
    //             { header: 'U.Medida', dataKey: 'un' },

    //         ],
    //         body: mat_inserido,
    //     });

    //     let transportadora = this.state.Transportadoras.filter(e => e.id === this.state.TransportadoraId);
    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },

    //         columns: [
    //             { header: '3. TRANSPORTADOR', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Razão social:', texto: transportadora.map(item => item.nome_fantasia) },
    //         { titulo: 'Endereço:', texto: transportadora.map(item => item.logradouro + ', ' + item.numero + ' - ' + item.bairro + ', ' + item.cidade + '-' + item.estado) },
    //         { titulo: 'Telefone:', texto: transportadora.map(item => item.responsavel.user_dado.telefone) },
    //         { titulo: 'E-mail:', texto: transportadora.map(item => item.responsavel.email) },
    //         { titulo: 'Descrição:', texto: transportadora.map(item => item.descricao) }],
    //     });

    //     let veiculo = this.state.Veiculos.filter(e => e.id === this.state.VeiculoId);
    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: '4. VEÍCULO', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Placa:', texto: veiculo.map(item => item.placa) },
    //         { titulo: 'Tipo de veículo:', texto: veiculo.map(item => item.tipo.nome) },
    //         { titulo: 'Marca:', texto: veiculo.map(item => item.marca.nome) },
    //         { titulo: 'Modelo:', texto: veiculo.map(item => item.modelo.nome) },
    //         { titulo: 'Ano:', texto: veiculo.map(item => item.ano) },
    //         { titulo: 'Conservação:', texto: veiculo.map(item => item.conservacao.nome) },
    //         { titulo: 'Nome do condutor:', texto: this.state.Motoristas.filter(m => m.id === this.state.MotoristaId).map(m => m.nome) }],
    //     });

    //     let destino = this.state.Destinos.filter(e => e.id === this.state.Destino);
    //     doc.autoTable({
    //         pageBreak: 'avoid',
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: '5. DESTINO', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Razão social:', texto: destino.map(item => item.fantasia) },
    //         { titulo: 'Endereço:', texto: destino.map(item => item.logradouro + ', ' + item.numero + ' - ' + item.bairro + ', ' + item.cidade + '-' + item.estado) },
    //         { titulo: 'Telefone:', texto: destino.map(item => item.user_id.user_dado.telefone) },
    //         { titulo: 'E-mail:', texto: destino.map(item => item.user_id.email) },
    //         { titulo: 'Licenciamento:', texto: destino.map(item => item.licenca) },
    //         { titulo: 'Número da licença:', texto: destino.map(item => item.numlicenca) },
    //         { titulo: 'Descrição:', texto: destino.map(item => item.descricao) }],
    //     });

    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: '6. FISCAL', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Chave da nota fiscal:', texto: this.state.ChaveNota },
    //         { titulo: 'Número e série da nota fiscal:', texto: this.state.NumeroNota + '-' + this.state.SerieNota }],
    //     });

    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
    //             texto: { fontStyle: 'normal', halign: 'left' }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         columns: [
    //             { header: '7. DESPACHO', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //         ],
    //         body: [{ titulo: 'Volume total:', texto: this.state.VolumeTotal + ' l' },
    //         { titulo: 'Receita:', texto: 'R$ ' + this.state.ReceitaTotal.replace('.', ',') },
    //         { titulo: 'Despesa:', texto: 'R$ ' + this.state.DespesaTotal.replace('.', ',') },
    //         { titulo: 'Saldo:', texto: 'R$ ' + this.state.Saldo.replace('.', ',') }],
    //     });

    //     doc.autoTable({
    //         theme: 'striped',
    //         tableLineWidth: 0.2,
    //         columnStyles: {
    //             titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right', lineWidth: 0 },
    //             texto: { fontStyle: 'normal', halign: 'left', lineWidth: 0 }
    //         },
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0.2 },
    //         columns: [
    //             { header: '8. RESPONSAVEÍS', dataKey: 'titulo' },
    //             { dataKey: 'texto' },
    //             { header: 'Nome', dataKey: 'nome' },
    //             { header: 'Data', dataKey: 'data' },
    //             { header: 'Assinatura', dataKey: 'ass' },
    //         ],
    //         body: [{ titulo: 'Gerador:', texto: ponto_coleta.nome_fantasia, nome: ponto_coleta.responsavel.name, data: '          ', ass: '' },
    //         { titulo: 'Transportador:', texto: transportadora.map(item => item.nome_fantasia), nome: transportadora.map(item => item.responsavel.name), data: '          ', ass: '' },
    //         { titulo: 'Destino:', texto: destino.map(item => item.fantasia), nome: destino.map(item => item.user_id.name), data: '          ', ass: '' }],
    //     });

    //     doc.autoTable({
    //         theme: 'plain',
    //         tableLineWidth: 0.2,
    //         tableLineColor: 'black',
    //         headStyles: { fontSize: 10, textColor: 'black', fillColor: null, lineColor: 'black' },
    //         bodyStyles: { fontSize: 10, lineWidth: 0 },
    //         head: [['9. Relato de intercorrências ou discrepância das indicações deste manifesto:']],
    //         body: [[], []],
    //     });

    //     doc.autoTable({
    //         theme: 'plain',
    //         tableLineColor: 'black',
    //         tableLineWidth: 0.2,
    //         headStyles: { fontSize: 10, textColor: 'black', fillColor: null, },
    //         bodyStyles: { fontSize: 8, lineWidth: 0 },
    //         head: [['10. Recebimento do destino: Certificação de recebimento dos resíduos/rejeitos descritos neste manifesto: ']],
    //         body: [[], ['                    ______________________________   ______________________________   ______________________________          '],
    //         ['                                                                                                           Assinatura                                                    '],
    //         [], ['OBS.: Preencher em 5 vias – 1a Gerador – 2a Transportador – 3a Destino – 4a Órgão Ambiental – 5a Via Gerador assinada pelo destino']],
    //     });
    //     // doc.save('table.pdf');  
    //     // doc.output('dataurlnewwindow');
    //     window.open(doc.output('bloburl'))
    // }

    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_editar_destinacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    console.log('resp', resp)

                    this.setState({
                        loading_screen: false,
                        Destinos: resp.destinos,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,
                        SolicitacoesSelecionadas: resp.destinacao.solicitacao_coleta,
                        TransportadoraId: resp.destinacao.transportadora_id,
                        // TransportadoraNome: resp.destinacao.transportadora.nome_fantasia,
                        TransportadoraNome: resp.destinacao.transportadora != null ? resp.destinacao.transportadora.nome_fantasia : 'Carro próprio',

                        Situacao: resp.destinacao.situacao,
                        Descricao: resp.destinacao.descricao != null ? resp.destinacao.descricao : '',
                        Solicitacao: resp.destinacao.solicitacao_id,
                        Destino: resp.destinacao.destino_id,
                        DestinoNome: resp.destinacao.destino.fantasia,
                        DataSolicitacao: resp.destinacao.data_solicitacao,
                        DataAgendamento: resp.destinacao.data_agendamento != null ? resp.destinacao.data_agendamento : '',
                        DataInicio: resp.destinacao.data_inicio != null ? resp.destinacao.data_inicio : '',
                        PesoTotal: resp.destinacao.peso_total,
                        VolumeTotal: resp.destinacao.volume_total,
                        ReceitaTotal: resp.destinacao.receita_total,
                        DespesaTotal: resp.destinacao.despesa_total,
                        Saldo: resp.destinacao.saldo,
                        MotoristaId: resp.destinacao.motorista_id,
                        VeiculoId: resp.destinacao.veiculo_id,
                        veiculos_motoristas: resp.destinacao.veiculos_motoristas,
                        // MotoristaNome: resp.destinacao.motorista.nome,
                        // VeiculoNome: resp.destinacao.veiculo.modelo.nome,
                        MateriaisInseridos: resp.destinacao.materiais,
                        ChaveNota: resp.destinacao.chave_nota != null ? resp.destinacao.chave_nota : '',
                        NumeroNota: resp.destinacao.numero_nota != null ? resp.destinacao.numero_nota : '',
                        SerieNota: resp.destinacao.serie_nota != null ? resp.destinacao.serie_nota : '',
                        SolicitacaoSelecionada: [resp.destinacao.solicitacao_coleta],
                        DestinoSelecionado: resp.destinacao.destino,
                        DataCriacao: resp.destinacao.data_criacao,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }


    finalizar() {
        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/finalizar_destinacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacao_coleta_id: this.props.match.params.id,
                solicitacao_id: this.state.Solicitacao,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataCriacao,
                data_solicitacao: this.state.DataSolicitacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,
                data_finalizado: this.state.DataFinalizado,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                descricao: this.state.Descricao,
                situacao: 'Finalizada',
                materiais_coletados: this.state.MateriaisInseridos,
                chave_nota: this.state.ChaveNota,
                numero_nota: this.state.NumeroNota,
                serie_nota: this.state.SerieNota,
                veiculos_motoristas: this.state.veiculos_motoristas
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }



    componentDidMount() {

        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }



    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to='/despacho' />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Finalize a destinação</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino: <span style={{ fontWeight: 'normal' }}>{this.state.DestinoNome}</span></label>
                                        </div>

                                        {this.state.SolicitacaoSelecionada.length > 0 && <><hr />
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados de solicitação em aberto:</label>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Solicitação de coleta: <span style={{ fontWeight: 'normal' }}>{this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}-{this.state.SolicitacaoSelecionada.descricao}</span></label>
                                            </div>
                                            <br />

                                            {this.state.SolicitacaoSelecionada.length > 0 && <>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "> <b className="col-sm-4 ">Data da solicitação: </b> {this.state.SolicitacaoSelecionada[0].data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Ponto de coleta:  </b> {this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Responsável: </b>{this.state.SolicitacaoSelecionada[0].responsavel.name}</p>
                                                </div>

                                            </>}
                                        </>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')+ ' '+(item.residuo_rejeito.unidade_principal.tipo=='Peso'?item.residuo_rejeito.unidade_principal.sigla:item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                                <td>{maskNumberBr(item.volume + '')+ ' '+(item.residuo_rejeito.unidade_principal.tipo=='Volume'?item.residuo_rejeito.unidade_principal.sigla:item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} onChange={(e) => {

                                                    if (e.target.value != '' && this.state.DataInicio == '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: e.target.value });
                                                    } else if (e.target.value != '' && this.state.DataInicio == '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }
                                                    else if (e.target.value == '' && this.state.DataInicio == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataAgendamento: e.target.value });
                                                    } else if (e.target.value == '' && this.state.DataInicio != '') {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }} disabled={this.state.DataInicio != '' ? true : false} max={this.state.DataAtual} onChange={(e) => {
                                                    if (e.target.value != '') {
                                                        this.setState({ Situacao: 'Iniciado' })
                                                    }
                                                    if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                        this.setState({ Situacao: 'Rascunho' })
                                                    }
                                                    if (e.target.value != '' && this.state.DataAgendamento != '') {
                                                        this.setState({ Situacao: 'Agendado' })
                                                    }
                                                    this.setState({ DataInicio: e.target.value })
                                                }} />
                                            </div>
                                        </div>
                                        {this.state.Situacao != 'Rascunho' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Finalizado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="data_finalizado" value={this.state.DataFinalizado} type="date" style={{ maxWidth: 168 }} min={this.state.DataCriacao} onChange={(e) => {
                                                    if (e.target.value != '' && e.target.value < this.state.DataCriacao) {
                                                        this.setState({ DataFinalizado: this.state.DataAtual })
                                                    } else {
                                                        this.setState({ DataFinalizado: e.target.value })
                                                    }
                                                }} />
                                            </div>
                                        </div>}

                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> {maskPrice(this.state.ReceitaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> {maskPrice(this.state.DespesaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.replace('.', ',')}</div>
                                        </div>

                                        <div className="row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Veículo</th>
                                                        <th>Motorista</th>
                                                        <th>Capacidade Peso</th>
                                                        <th>Capacidade Volume</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.veiculos_motoristas?.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.veiculo.modelo.nome}</td>
                                                            <td>{item.motorista.nome}</td>

                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)}  {item.veiculo.unidade_peso.sigla}</td>
                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)}  {item.veiculo.unidade_volume.sigla}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {this.state.Descricao != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações:  <span style={{ fontWeight: 'normal' }}>{this.state.Descricao}</span> </label>
                                        </div>}
                                        {this.state.ChaveNota != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Chave da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.ChaveNota}</span></label>
                                        </div>}
                                        {this.state.NumeroNota != '' && <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Número da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.NumeroNota}</span> </label>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Série da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.SerieNota}</span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/despacho"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.finalizar()} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Finalizar </button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='     OK     '
                                confirmBtnBsStyle="success"
                            >
                                Para gerar o MTR você deve preencher todos os campos que são obrigatórios.
                            </SweetAlert>

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewDestinacaoColeta);


