
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { URL } from '../../../variables';
import { MdErrorOutline } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { maskNumberBr } from '../../../Auxiliar/Masks';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class RelatoriosColetaTransporte extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msgErro: false,
            redirect: false,
            path: '/',
            loading_gerar: false,
            loading_screen: true,
            DespachosAgrupados: [],
            labels: [],
            series: [],
            Materiais: [],
            MateriaisSelecionados: [],
            DataAtual: '',
            DataInicial: '',
            DataFinal: '',
            TipoMaterial: 'Todos',
            Material: 'Todos',
            Descricao: '',
            Peso_total: '',
            Receita_total: '',
            Despesa_total: '',
            Saldo_total: '',
            Titulo: '',
            TipoRelatorio: 'Receita',
            Situacao: 'Todas',
            RespTipo: 'Receita',
            AnoAtual: '',
            loading_table: false,
            Despachos_pag: [],
            page_gerar: false,
            total_pages: 1,
            page: 1
        };
    }

    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            AnoAtual: ano
        })
        this.get_Despachos(this.state.page);
    }

    get_Despachos(page) {
        if (this.state.loading_screen == false && this.state.Despachos_pag == null) {
            this.setState({ loading_screen: true });
        } else {
            this.setState({ loading_table: true });
        }
        fetch(`${URL}api/get_relatorios_coleta_transporte?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    if (resp.despachos_group.length == 0) {
                        this.setState({
                            loading_table: false,
                            loading_gerar: false,
                            loading_screen: false,
                            Descricao: 'Não há registro suficiente para gerar relatório com os dados fornecidos ...',
                            Materiais: resp.materiais,
                            MateriaisSelecionados: resp.materiais,
                            labels: resp.despachos_group.map(item => item.fantasia),
                            series: resp.despachos_group.map(item => parseFloat(item.soma)),
                            Peso_total: 0,
                            Receita_total: 0,
                            Despesa_total: 0,
                            Saldo_total: 0,
                            DespachosAgrupados: resp.despachos_group,
                            Titulo: 'Não encontrado',
                            Despachos_pag: resp.despachos_page,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    } else {
                        this.setState({
                            loading_table: false,
                            loading_screen: false,
                            loading_search: false,
                            Materiais: resp.materiais,
                            MateriaisSelecionados: resp.materiais,
                            labels: resp.despachos_group.map(item => item.fantasia),
                            series: resp.despachos_group.map(item => parseFloat(item.soma)),
                            Peso_total: resp.despachos_group.map(item => parseFloat(item.peso)).reduce((t, n) => t + n),
                            Receita_total: resp.despachos_group.map(item => parseFloat(item.soma)).reduce((t, n) => t + n),
                            Despesa_total: resp.despachos_group.map(item => parseFloat(item.despesas)).reduce((t, n) => t + n),
                            Saldo_total: resp.despachos_group.map(item => parseFloat(item.saldo)).reduce((t, n) => t + n),
                            DespachosAgrupados: resp.despachos_group,
                            Descricao: resp.descricao,
                            Titulo: resp.titulo,
                            Despachos_pag: resp.despachos_page,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    }
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }

    GerarRelatorio(page, gerador) {
        if (this.state.DataInicial != '' && this.state.DataFinal == '' || this.state.DataInicial == '' && this.state.DataFinal != '') {
            this.setState({
                msgErro: 'Digite ambas ou nenhuma data'
            })
            return;
        }
        if (this.state.DataInicial != '' && this.state.DataFinal != '' && this.state.DataInicial > this.state.DataFinal) {
            this.setState({
                msgErro: 'Datas conflitantes'
            })
            return;
        }
        if (this.state.DataInicial != '' && this.state.DataFinal != '' && this.state.DataInicial > this.state.DataAtual || this.state.DataFinal > this.state.DataAtual) {
            this.setState({
                msgErro: 'Data inválida. Não é possível gerar relatórios futuros'
            })
            return;
        }
        if (gerador) {
            this.setState({ loading_gerar: true, loading_screen: true, msgErro: false, page_gerar: true });
        } else {
            this.setState({ loading_table: true });
        }
        fetch(`${URL}api/gerar_relatorios_coleta_transporte`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data_inicial: this.state.DataInicial,
                data_final: this.state.DataFinal,
                tipo_material: this.state.TipoMaterial,
                material: this.state.Material,
                tipo_relatorio: this.state.TipoRelatorio,
                situacao: this.state.Situacao,
                page: page,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({
                        Descricao: 'Não foi posivel gerar o relatório por falta de informações...',
                        Peso_total: 0,

                        loading_gerar: false,
                        msgErro: erro,
                        loading_screen: false
                    });
                }
                else {
                    console.log(resp)


                    if (resp.despachos_group.length == 0) {
                        this.setState({
                            loading_table: false,
                            loading_gerar: false,
                            loading_screen: false,
                            Descricao: 'Não há registro suficiente para gerar relatório com os dados fornecidos ...',
                            labels: resp.despachos_group.map(item => item.fantasia),
                            series: resp.despachos_group.map(item => parseFloat(item.soma)),
                            Peso_total: 0,
                            Receita_total: 0,
                            Despesa_total: 0,
                            Saldo_total: 0,
                            DespachosAgrupados: resp.despachos_group,
                            RespTipo: resp.tipo_relatorio,
                            Titulo: 'Não encontrado',
                            Despachos_pag: resp.despachos_page,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    } else {

                        this.setState({
                            loading_table: false,
                            loading_gerar: false,
                            loading_screen: false,
                            labels: resp.despachos_group.map(item => item.fantasia),
                            series: resp.despachos_group.map(item => parseFloat(item.soma)),
                            Peso_total: resp.despachos_group.map(item => parseFloat(item.peso)).reduce((t, n) => t + n),
                            Receita_total: resp.tipo_relatorio == 'Receita' ? resp.despachos_group.map(item => parseFloat(item.soma)).reduce((t, n) => t + n) : resp.despachos_group.map(item => parseFloat(item.receita)).reduce((t, n) => t + n),
                            Despesa_total: resp.tipo_relatorio == 'Despesa' ? resp.despachos_group.map(item => parseFloat(item.soma)).reduce((t, n) => t + n) : resp.despachos_group.map(item => parseFloat(item.despesas)).reduce((t, n) => t + n),
                            Saldo_total: resp.despachos_group.map(item => parseFloat(item.saldo)).reduce((t, n) => t + n),
                            DespachosAgrupados: resp.despachos_group,
                            Descricao: resp.descricao,
                            RespTipo: resp.tipo_relatorio,
                            Titulo: resp.titulo,
                            Despachos_pag: resp.despachos_page,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    }

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_gerar: false, msgErro: 'Erro ao pegar resposta do servidor', loading_screen: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_gerar: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', loading_screen: false });
            });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.msgErro != '' && <div>
                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                    </div>
                    <br />
                </div>}
                <div className=" col-sm-12">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <label className="col-sm-12 col-form-label">Relatório</label>
                            <div className="input-group mb-3">
                                <select value={this.state.TipoRelatorio} className="form-control"
                                    onChange={(e) => {
                                        this.setState({
                                            DataInicial: '',
                                            DataFinal: '',
                                            TipoRelatorio: e.target.value
                                        });
                                    }}>
                                    <option value={'Receita'}>Receita por estoque</option>
                                    <option value={'Despesa'}>Despesa por estoque</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <label className="col-sm-12 col-form-label">Situação</label>
                            <div className="input-group mb-3">
                                <select value={this.state.Situacao} className="form-control"
                                    onChange={(e) => {
                                        this.setState({ Situacao: e.target.value });
                                    }}>
                                    <option value={'Todas'}>Todas</option>
                                    <option value={'Em andamento'} defaultValue>Em andamento</option>
                                    <option value={'Finalizadas'}>Coleta recebida</option>

                                </select>
                            </div>
                        </div>

                        <div className="col-md-3 col-12">
                            <label className="col-sm-12 col-form-label">Data inicial: </label>
                            <div className="col-sm-12">
                                <input className="form-control" type="date" style={{ maxWidth: 168 }} max={this.state.DataAtual} onChange={(e) => this.setState({ DataInicial: e.target.value })} value={this.state.DataInicial} />
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <label className="col-sm-12 col-form-label">Data final:</label>
                            <div className="input-group mb-3">
                                <input className="form-control" type="date" style={{ maxWidth: 168 }} max={this.state.DataAtual} value={this.state.DataFinal} onChange={(e) => {
                                    this.setState({
                                        DataFinal: e.target.value
                                    })
                                }} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className=" col-sm-12">
                    <div className="row">

                        <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">Tipo de material</label>
                            <div className="input-group mb-3">
                                <select value={this.state.TipoMaterial} className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value == 'Todos') {
                                            this.setState({
                                                TipoMaterial: e.target.value,
                                                Material: 'Todos',
                                                MateriaisSelecionados: this.state.Materiais,
                                            });
                                        } else {
                                            let materiais = this.state.Materiais.filter(item => item.tipo == e.target.value);
                                            this.setState({
                                                TipoMaterial: e.target.value,
                                                Material: 'Todos',
                                                MateriaisSelecionados: materiais,
                                            });
                                        }
                                    }}>
                                    <option value={'Todos'}>Todos</option>
                                    <option value={'Resíduo'}>Resíduo</option>
                                    <option value={'Rejeito'}>Rejeito</option>

                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">Material</label>
                            <div className="input-group mb-3">
                                <select value={this.state.Material} className="form-control"
                                    onChange={(e) => {
                                        this.setState({
                                            Material: e.target.value
                                        })
                                    }}>
                                    <option value={'Todos'}>Todos</option>
                                    {this.state.MateriaisSelecionados.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loading_gerar == false && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="button" className="btn btn-info" style={{ backgorundColor: '#f2f2f2', marginBottom: '5px' }} onClick={() => this.GerarRelatorio(1, true)}>Gerar relatório</button>
                </div>}
                <hr />

                {this.state.loading_screen == false &&
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" id="card">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.state.Titulo}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>{this.state.Descricao}
                                    <IoIosInformationCircleOutline data-tip={`Para ver dados menores que não aparecem no gráfico, clique na legenda`} />
                                    <ReactTooltip place="top" effect="solid" />
                                </p>
                                <br />
                                {this.state.Titulo == 'Não encontrado' && <div style={{ fontSize: '100px', textAlign: 'center', color: '#ffc107' }}><MdErrorOutline /></div>}

                                {this.state.Titulo != 'Não encontrado' &&
                                    <div style={{ width: '80%', position: 'center' }}>
                                        <Chart
                                            options={
                                                {
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    annotations: {
                                                        texts: [this.state.RespTipo]
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '50%',
                                                                labels: {
                                                                    show: true,
                                                                    name: {
                                                                        show: true,
                                                                        color: 'black',
                                                                        fontWeight: 'bold'
                                                                    },
                                                                    value: {
                                                                        offsetY: 0,
                                                                        show: true,
                                                                        formatter: function (val, opts) {
                                                                            console.log(opts);
                                                                            let tipo = opts.config.annotations.texts[0];
                                                                            return 'R$ ' + maskNumberBr(parseFloat(val).toFixed(2));
                                                                        }
                                                                    },
                                                                    total: {
                                                                        show: true,
                                                                        formatter: function (w) {
                                                                            console.log(w);
                                                                            let tipo = w.config.annotations.texts[0];
                                                                            let resultado = w.globals.seriesTotals.reduce((total, numero) => {
                                                                                return total + numero;
                                                                            });
                                                                            console.log(tipo);
                                                                            return 'R$ ' + maskNumberBr(resultado.toFixed(2));
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    labels: this.state.labels,
                                                    legend: {
                                                        formatter: function (seriesName,) {
                                                            return seriesName.charAt(0).toUpperCase() + seriesName.slice(1);
                                                        }, position: 'right', fontSize: '16px', fontWeight: 'bold', horizontalAlign: 'right', fontFamily: 'Roboto, sans-serif'
                                                    },
                                                }
                                            }
                                            series={this.state.series}
                                            height="350px"
                                            width='100%'
                                            type="donut"
                                        /> </div>}


                            </div>
                            <div>
                                <ul className="list-group list-group-flush">
                                    <Table responsive="sm">
                                        <thead>
                                            <tr>
                                                <th>Favorecido/Credor</th>
                                                <th style={{ textAlign: 'right' }}>Valor(Kg)</th>
                                                <th style={{ textAlign: 'right' }}>Receita</th>
                                                <th style={{ textAlign: 'right' }}>Despesa</th>
                                                <th style={{ textAlign: 'right' }}>Saldo</th>
                                            </tr>
                                        </thead>
                                        {this.state.loading_table == false && <tbody>
                                            {this.state.Despachos_pag.map((item, id) => (
                                                <tr key={id}>
                                                    <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'blue' }}>{item.fantasia.charAt(0).toUpperCase() + item.fantasia.slice(1)}</td>
                                                    <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{maskNumberBr(parseFloat(item.peso).toFixed(2)) + ' Kg'}</td>
                                                    <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{this.state.RespTipo == 'Receita' ? 'R$ ' + maskNumberBr(parseFloat(item.soma).toFixed(2).toString()) : 'R$ ' + maskNumberBr(parseFloat(item.receita).toFixed(2).toString())}</td>
                                                    <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{this.state.RespTipo == 'Despesa' ? 'R$ ' + maskNumberBr(parseFloat(item.soma).toFixed(2).toString()) : 'R$ ' + maskNumberBr(parseFloat(item.despesas).toFixed(2).toString())}</td>
                                                    <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{'R$ ' + maskNumberBr(parseFloat(item.saldo).toFixed(2).toString()).replace('--', '-')}</td>
                                                </tr>))}
                                        </tbody>}
                                    </Table>
                                    {this.state.loading_table == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                    <div className="col-12 d-flex justify-content-end" style={{ marginBottom: '10px' }}  >
                                        <ReactPaginate
                                            previousLabel={'Anterior'}
                                            nextLabel={'Próxima'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick.bind(this)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            initialPage={this.state.page - 1}
                                        />
                                    </div>
                                </ul>
                                <ul className="list-group list-group-flush">
                                    <Table responsive="sm">
                                        <tbody>
                                            <tr >
                                                <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black', width: '35%' }}>Total</td>
                                                <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{maskNumberBr(parseFloat(this.state.Peso_total).toFixed(2)) + ' Kg'}</td>
                                                <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{'R$ ' + maskNumberBr(parseFloat(this.state.Receita_total).toFixed(2).toString())}</td>
                                                <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{'R$ ' + maskNumberBr(parseFloat(this.state.Despesa_total).toFixed(2).toString())}</td>
                                                <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{'R$ ' + maskNumberBr(parseFloat(this.state.Saldo_total).toFixed(2).toString())}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </ul>
                            </div>
                        </div>
                    </div>
                }

                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
            </div>

        );
    }
    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            if (this.state.page_gerar == false) {
                this.get_Despachos(page.selected + 1);
            } else {
                this.GerarRelatorio(page.selected + 1);
            }

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(RelatoriosColetaTransporte);


