import React from 'react'
import Unidades from './Unidades';
import UnidadesPendentes from './UnidadesSigor';
import Filter from '../../../Auxiliar/Filter';
import { useSelector } from 'react-redux';

const SelecionarJsUnidade = () => {
    const permission = useSelector(state => state.AppReducer.permissoes.sigor.acesso)

    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        unidade: { value: true, label: 'Unidades Finalizadas', checked: true },
        unidadePendente: { value: false, label: 'Unidades Pendentes(SIGOR)', checked: false, disabled: !permission },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Unidades</h4>
                    <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados as unidades do sistema</p>
                </div>
                <div className="row">
                    {options.unidade.value &&
                        <Unidades dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.unidadePendente.value &&
                        <UnidadesPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsUnidade