import React from 'react'

const VerifySigor = (props) => {
  // console.log('props', props)
  let resp = {}
  switch (props.validation) {
    case 'version':
      if (props.rr) {
        if (props.item.sigor_residuo_codigo && props.item.sigor_unidade_codigo && props.item.sigor_classe_codigo && props.item.sigor_tratamento_codigo && props.item.sigor_acondicionamento_codigo && props.item.sigor_estado_codigo) {
          resp = { status: true, message: 'OK' }
        } else {
          resp = { status: false, message: 'Versão incompativel, delete e adicione novamente o item no sistema' }
        }
      }
      if (props.item.sigor_codigos) {
        resp = { status: true, message: 'OK' }
      } else {
        resp = { status: false, message: 'Versão incompativel, delete e adicione novamente o item no sistema' }
      }
      return resp

    case 'danger':
      let errors = []
      const classe = props.state.classes.filter(item => item.id === props.state.classe)[0]
      const estado = props.state.estados.filter(item => item.id === props.state.estado)[0]
      const unidade = props.state.unidades.filter(item => item.id === props.state.unidade_peso)[0]

      //Resíduos com o Código do IBAMA igual a 200132, Grupo A, Grupo B, Grupo C, Grupo D, grupo E, GrupoA1, GrupoA2, grupoA3, GrupoA4, GrupoA5. Valores possíveis para o campo “greCodigo” -> 1= I, 2= II, 3= III, 4= N/A
      if (classe.relation) {
        if (props.state.codigoIbama === '200132' || classe.relation.inherited_code.classe_codigo === 41 || classe.relation.inherited_code.classe_codigo === 32 || classe.relation.inherited_code.classe_codigo === 33 || classe.relation.inherited_code.classe_codigo === 34 || classe.relation.inherited_code.classe_codigo === 35 || classe.relation.inherited_code.classe_codigo === 21 || classe.relation.inherited_code.classe_codigo === 22 || classe.relation.inherited_code.classe_codigo === 23 || classe.relation.inherited_code.classe_codigo === 24 || classe.relation.inherited_code.classe_codigo === 25) {
          //residuo de risco, salvar “marNumeroONU”, “marClasseRisco”, “marNomeEmbarque” e “greCodigo” - AÇÃO INCOMPLETA
          if (!props.state.danger) {
            //para resíduos de risco sem conter '*' no código ibama, o campo unidade deve ser informado obrigatoriamente Quilograma ou Litro
            if (unidade.sigor_codigos.unidade_codigo !== 21 || unidade.sigor_codigos.unidade_codigo !== 2) errors = [...errors, 'O campo unidade deve ser informado obrigatoriamente como Quilograma ou Litro']
          }
        }
      } else {
        if (props.state.codigoIbama === '200132' || classe.sigor_codigos.classe_codigo === 41 || classe.sigor_codigos.classe_codigo === 32 || classe.sigor_codigos.classe_codigo === 33 || classe.sigor_codigos.classe_codigo === 34 || classe.sigor_codigos.classe_codigo === 35 || classe.sigor_codigos.classe_codigo === 21 || classe.sigor_codigos.classe_codigo === 22 || classe.sigor_codigos.classe_codigo === 23 || classe.sigor_codigos.classe_codigo === 24 || classe.sigor_codigos.classe_codigo === 25) {
          //residuo de risco, salvar “marNumeroONU”, “marClasseRisco”, “marNomeEmbarque” e “greCodigo” - AÇÃO INCOMPLETA
          if (!props.state.danger) {
            //para resíduos de risco sem conter '*' no código ibama, o campo unidade deve ser informado obrigatoriamente Quilograma ou Litro
            if (unidade.sigor_codigos.unidade_codigo !== 21 || unidade.sigor_codigos.unidade_codigo !== 2) errors = [...errors, 'O campo unidade deve ser informado obrigatoriamente como Quilograma ou Litro']
          }
        }
      }

      if (props.state.codigoIbama === '200121(*)') {
        //para lâmpadas fluorescentes, de vapor de sódio e mercúrio e de luz mista o campo unidade deve ser informado obrigatoriamente como Unidade
        if (props.state.unidade.sigor_codigos.unidade_codigo !== 1 || props.state.unidade.relation.inherited_code.unidade_codigo !== 1) errors = [...errors, 'O campo unidade deve ser informado obrigatoriamente como Unidade']
      }

      if (props.state.codigoIbama === '200304') {
        //Para o Resíduo com o Código do IBAMA igual a 200304, Lodos de fossas sépticas, o campo estado físico deve ser informado obrigatoriamente Líquido
        if (estado.sigor_codigos.estado_codigo !== 2 || estado.relation.inherited_code.estado_codigo !== 2) errors = [...errors, 'Para Lodos de fossas sépticas, o campo estado físico deve ser informado obrigatoriamente Líquido']
      }
      if (errors.lenght > 0) {
        resp = { status: false, message: errors }
      } else {
        resp = { status: true, message: errors }
      }
      return resp

    default:
      return resp
  }
}

export default VerifySigor