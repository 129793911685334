import React, { useCallback, useEffect, useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaUserAlt, FaTruckMoving, FaBriefcase, FaFolderPlus, FaCog, FaChartLine, FaRegComments, FaRecycle, FaUserNinja, FaUserSecret, FaRegChartBar, FaWrench } from 'react-icons/fa';
import sidebarBg from './assets/bg1.jpg';
import { NavLink } from 'react-router-dom';
import { MdCopyright } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import './styles/scrollBar.css';
import { DEBUG } from '../variables';


const Aside = ({ image, collapsed, toggled, handleToggleSidebar }) => {
  // #084808
  let user = useSelector(store => store.AppReducer.user);
  let permissoes = useSelector(store => store.AppReducer.permissoes);
  let logo_path = useSelector(store => store.AppReducer.logo);
  const permissions = useSelector((store) => store.AppReducer.permissoes);
  let year = new Date();
  const checar_tipo_usuario = useCallback((parameters) => {
    let permission = false;
    for (let i = 0; i < parameters.length; i++) {
      let tipo = parameters[i].split(':')[0];
      let tipo_dado = parameters[i].split(':')[1];
      if (user.tipo_usuario.permissoes[tipo] != undefined && user.tipo_usuario.permissoes[tipo][tipo_dado == undefined ? 'leitura' : tipo_dado] == true) {
        permission = true;
      }
    }
    return permission;
  }, [user]);

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      className='hidethis'
      breakPoint="md"
      style={{ position: 'fixed' }}
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader style={{ background: '#222d32', height: 70 }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          {collapsed == true && <span>
            <img src={logo_path} alt="" height='60' style={{
              height: '60px',
              width: '90%',
              objectFit: 'contain'
            }} />
          </span>}
          {collapsed == false && <i>
            <img className="img-fluid" src={logo_path} alt="" height="100" style={{ height: '40px' }} />
          </i>}
        </div>
      </SidebarHeader>
      <SidebarContent className='scrollMenu' style={{ background: '#222d32', overflowY: 'auto' }}>
        <Menu iconShape="circle">
          {/* <MenuItem icon={<FaGem />}> {'Cadastros'}</MenuItem> */}
          {permissoes['configuracao_geral'] != undefined && permissoes['configuracao_geral'].acesso == true && checar_tipo_usuario(['dados_empresa', 'logs']) && <SubMenu title="Configuração Geral" icon={<FaCog />}>
            {checar_tipo_usuario(['dados_empresa:escrita']) && <MenuItem  > <NavLink to="/empresa" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Dados da Empresa</NavLink></MenuItem>}
            {checar_tipo_usuario(['logs']) && <MenuItem  ><NavLink to="/logs" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Log</NavLink></MenuItem>}
            {(permissions.sigor?.acesso) && <MenuItem  > <NavLink to="/sigor" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Sigor</NavLink></MenuItem>}
          </SubMenu>}

          {permissoes['gestao_acesso'] != undefined && permissoes['gestao_acesso'].acesso == true && checar_tipo_usuario(['permissoes', 'pessoas']) && <SubMenu title="Gestão de Acesso" icon={<FaUserAlt />}>
            {checar_tipo_usuario(['permissoes']) && <MenuItem  ><NavLink to="/tipos_usuario" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Perfil e Permissão</NavLink></MenuItem>}
            {checar_tipo_usuario(['pessoas']) && <MenuItem  ><NavLink to="/pessoas" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Cadastro de Usuário</NavLink></MenuItem>}
          </SubMenu>}

          {/* <SubMenu title="Portal de Resíduos" icon={<FaWineBottle />}>
            <MenuItem  >Painel</MenuItem>
          </SubMenu> */}

          {permissoes['personalizacao_residuos'] != undefined && permissoes['personalizacao_residuos'].acesso == true
            && checar_tipo_usuario(['unidades', 'classes', 'estados_fisicos', 'acondicionamentos', 'destinacao_disposicao', 'armazenamento_temporario', 'tratamento', 'periculosidade'])
            && <SubMenu title="Personalização Resíduo" icon={<FaWrench />}>
              {checar_tipo_usuario(['unidades']) && <MenuItem  > <NavLink to="/unidades" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Unidade de Medida</NavLink></MenuItem>}
              {checar_tipo_usuario(['classes']) && <MenuItem  > <NavLink to="/classes" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Classe de Resíduo</NavLink></MenuItem>}
              {checar_tipo_usuario(['estados_fisicos']) && <MenuItem  > <NavLink to="/estados" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Estado Físico</NavLink></MenuItem>}
              {checar_tipo_usuario(['acondicionamentos']) && <MenuItem  > <NavLink to="/acondicionamentos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Acondicionamentos</NavLink></MenuItem>}
              {checar_tipo_usuario(['destinacao_disposicao']) && <MenuItem  > <NavLink to="/destinacao_disposicao" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Destinação/Disposição</NavLink></MenuItem>}
              {checar_tipo_usuario(['armazenamento_temporario']) && <MenuItem  > <NavLink to="/armazenamentos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Armazenamento Temporário</NavLink></MenuItem>}
              {checar_tipo_usuario(['tratamento']) && <MenuItem  > <NavLink to="/tratamentos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Tratamento</NavLink></MenuItem>}
              {checar_tipo_usuario(['periculosidade']) && <MenuItem  > <NavLink to="/periculosidades" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Periculosidade</NavLink></MenuItem>}
            </SubMenu>}

          {permissoes['personalizacao_veiculos'] != undefined && permissoes['personalizacao_veiculos'].acesso == true && checar_tipo_usuario(['tipos_veiculos', 'marcas_veiculos', 'modelos_veiculos', 'conservacoes_veiculos']) && <SubMenu title="Personalização Veículo" icon={<FaWrench />}>
            {checar_tipo_usuario(['tipos_veiculos']) && <MenuItem  > <NavLink to="/tipos_veiculos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Tipo</NavLink></MenuItem>}

            {checar_tipo_usuario(['marcas_veiculos']) && <MenuItem  > <NavLink to="/marcas" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Marca</NavLink></MenuItem>}

            {checar_tipo_usuario(['modelos_veiculos']) && <MenuItem  > <NavLink to="/modelos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Modelo</NavLink></MenuItem>}

            {checar_tipo_usuario(['conservacoes_veiculos']) && <MenuItem  > <NavLink to="/conservacoes" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Conservação</NavLink></MenuItem>}
          </SubMenu>}

          {permissoes['cadastro'] != undefined && permissoes['cadastro'].acesso == true && checar_tipo_usuario(['residuos_rejeitos', 'estoques', 'pontos_coleta', 'transportadoras', 'veiculos', 'motoristas', 'destinos']) && <SubMenu title="Cadastros" icon={<FaFolderPlus />}>
            {checar_tipo_usuario(['residuos_rejeitos']) && <MenuItem  > <NavLink to="/residuos_rejeitos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Resíduo</NavLink></MenuItem>}
            {checar_tipo_usuario(['estoques']) && <MenuItem  > <NavLink to="/estoques" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Estoque</NavLink></MenuItem>}
            {checar_tipo_usuario(['pontos_coleta']) && <MenuItem  > <NavLink to="/pontos_coleta" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Ponto de Coleta</NavLink></MenuItem>}
            {checar_tipo_usuario(['transportadoras']) && <MenuItem  > <NavLink to="/transportadoras" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Transportadora</NavLink></MenuItem>}
            {checar_tipo_usuario(['veiculos']) && <MenuItem  > <NavLink to="/veiculos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Veículo</NavLink></MenuItem>}
            {checar_tipo_usuario(['motoristas']) && <MenuItem  > <NavLink to="/motoristas" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Motorista</NavLink></MenuItem>}
            {checar_tipo_usuario(['destinos']) && <MenuItem><NavLink to="/destinos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Destino</NavLink></MenuItem>}
          </SubMenu>}

          {permissoes['coleta_transporte'] != undefined && permissoes['coleta_transporte'].acesso == true && checar_tipo_usuario(['registros_entrada_coleta', 'estoques_coleta', 'solicitacoes_coleta', 'solicitacao_simplificada:escrita', 'realizar_despacho', 'receber_coleta']) && <SubMenu title="Coleta e Transporte" icon={<FaTruckMoving />}>
            {checar_tipo_usuario(['registros_entrada_coleta']) && <MenuItem  ><NavLink to="/entrada_coleta" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Registrar Entrada</NavLink></MenuItem>}
            {checar_tipo_usuario(['estoques_coleta']) && <MenuItem  ><NavLink to="/estoque_coleta_transporte" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Visualizar Estoque</NavLink></MenuItem>}
            {checar_tipo_usuario(['solicitacoes_coleta']) && <MenuItem  ><NavLink to="/solicitar_coleta" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Solicitar Coleta</NavLink></MenuItem>}
            {/* {checar_tipo_usuario(['solicitacao_simplificada:escrita']) && <MenuItem  ><NavLink to="/solicitar_coleta_simplificada" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Solicitação simplificada</NavLink></MenuItem>} */}
            {checar_tipo_usuario(['realizar_despacho']) && <MenuItem  ><NavLink to="/despacho" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}> Realizar Despacho</NavLink></MenuItem>}
            {checar_tipo_usuario(['receber_coleta']) && <MenuItem  ><NavLink to="/despachos_abertos" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}> Receber Materiais</NavLink></MenuItem>}
            {(permissions.sigor?.acesso) && <MenuItem><NavLink to="/mtrs" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Manifestos</NavLink></MenuItem>}
          </SubMenu>}

          {permissoes['triagem'] != undefined && permissoes['triagem'].acesso == true && checar_tipo_usuario(['pesagem', 'separacao', 'relatorios_sub']) && <SubMenu title="Triagem" icon={<FaRecycle />}>
            {checar_tipo_usuario(['pesagem']) && <MenuItem  ><NavLink to="/pesagens" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Pesagem</NavLink></MenuItem>}
            {checar_tipo_usuario(['separacao']) && <MenuItem  ><NavLink to="/separacao" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Separação</NavLink></MenuItem>}
            {checar_tipo_usuario(['relatorios_sub']) && <MenuItem  ><NavLink to="/relatorio" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Relatório</NavLink></MenuItem>}
          </SubMenu>}

          {permissoes['gestao_interna'] != undefined && permissoes['gestao_interna'].acesso == true && checar_tipo_usuario(['registros_entrada_interna', 'estoques_interna', 'destinacao_interna']) && <SubMenu title="Gestão Interna" icon={<FaBriefcase />}>
            {checar_tipo_usuario(['registros_entrada_interna']) && <MenuItem  ><NavLink to="/registro_gestao_interna" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Registrar Entrada</NavLink></MenuItem>}
            {checar_tipo_usuario(['estoques_interna']) && <MenuItem  ><NavLink to="/estoque_gestao_interna" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Visualizar Estoque</NavLink></MenuItem>}
            {checar_tipo_usuario(['destinacao_interna']) && <MenuItem  ><NavLink to="/destinacao_interna" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Realizar destinação</NavLink></MenuItem>}
            {permissoes?.blueen_open?.acesso && <>
              {checar_tipo_usuario(['relacionamento_externo']) && <MenuItem  ><NavLink to="/relacionamentos_externos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Relacionamento Externo</NavLink></MenuItem>}
              {checar_tipo_usuario(['horario_funcionamento']) && <MenuItem  ><NavLink to="/horarios_funcionamento" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Horário de Funcionamento</NavLink></MenuItem>}
              {checar_tipo_usuario(['agendamento']) && <MenuItem  ><NavLink to="/agendamentos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Agendamento</NavLink></MenuItem>}
            </>}
          </SubMenu>}

          {permissoes['relatorios'] != undefined && permissoes['relatorios'].acesso == true &&
            ((permissoes['coleta_transporte'] != undefined && permissoes['coleta_transporte'].acesso == true) ||
              (permissoes['gestao_interna'] != undefined && permissoes['gestao_interna'].acesso == true)) &&
            checar_tipo_usuario(['relatorios_coleta', 'relatorios_interna']) &&
            <SubMenu title="Relatórios e Indicadores" icon={<FaRegChartBar />}>
              {/* {checar_tipo_usuario(['mapa_coleta']) && <MenuItem  ><NavLink to="/coleta_transporte/mapa" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Coleta e Transporte - Mapa</NavLink></MenuItem>}
            {checar_tipo_usuario(['dashboard_coleta']) && <MenuItem  ><NavLink to="/coleta_transporte/dashboard" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Coleta e Transporte - Dashboard</NavLink></MenuItem>}
            {checar_tipo_usuario(['relatorios_coleta']) && <MenuItem  ><NavLink to="/relatorio_coleta_transporte" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Coleta e Transporte - Relatório</NavLink></MenuItem>}
            {checar_tipo_usuario(['dashboard_gestao_interna']) && <MenuItem  ><NavLink to="/gestao_interna/dashboard" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Gestão Interna - Dashboard</NavLink></MenuItem>}
            {checar_tipo_usuario(['relatorios_interna']) && <MenuItem  ><NavLink to="/relatorios_gestao_interna" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Gestão Interna - Relatório</NavLink></MenuItem>} */}

              {permissoes['coleta_transporte'] != undefined && permissoes['coleta_transporte'].acesso == true && checar_tipo_usuario(['relatorios_coleta']) && <MenuItem  ><NavLink to="/relatorios/coleta_transporte" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Coleta e Transporte</NavLink></MenuItem>}
              {permissoes['gestao_interna'] != undefined && permissoes['gestao_interna'].acesso == true && checar_tipo_usuario(['relatorios_interna']) && <MenuItem  ><NavLink to="/relatorios/gestao_interna" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Gestão Interna</NavLink></MenuItem>}
            </SubMenu>}

          {permissoes['comunicacao'] != undefined && permissoes['comunicacao'].acesso == true && checar_tipo_usuario(['comunicacao']) && <SubMenu title="Comunicação" icon={<FaRegComments />}>
            {checar_tipo_usuario(['comunicacao']) && <MenuItem  ><NavLink to="/chat_geral" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Chat Geral</NavLink></MenuItem>}
          </SubMenu>}

          {user.name == "Super Admin Verdaz" && user.tipo_usuario.nome == "SuperAdmin" && user.email == "superadmin@verdaz.com.br" && <SubMenu title="SuperAdmin" icon={<FaFolderPlus />}>
            {<MenuItem icon={<FaUserSecret />}><NavLink to="/superAdmin" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Gestão de Acessos </NavLink></MenuItem>}
            {<MenuItem><NavLink to="/mudarIcones" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Mudar Logo</NavLink></MenuItem>}
            {<MenuItem><NavLink to="/empresaDomain" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>Empresa</NavLink></MenuItem>}
          </SubMenu>}
        </Menu>

      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', background: '#222d32' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="http://www.verdaz.com.br"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {`CopyRight Blueen ${year.getFullYear()}`}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
