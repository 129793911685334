import React, { useCallback, useEffect, useState } from "react";
import ProfileMenu from "./ProfileMenu";
import { NAME, URL, URLopen } from "./variables";
import { connect, useDispatch } from "react-redux";
import Logo from "./assets/images/logo3.png";
import { FaBars, FaRegBell } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Badge, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { notificationCounter } from "./actions/AppActions";
import { notifications, wsConnection } from "./actions/WebsocketActions";
import notificationSound from "./assets/sounds/notificacao_teste.mp3";

const Header = ({
  handleToggleSidebar,
  handleCollapsedChange,
  toggled,
  collapsed,
}) => {
  const marginLeft =
    toggled == false || window.innerWidth <= 768
      ? 0
      : collapsed == false
      ? 270
      : 80;

  const reducer = useSelector((state) => state.AppReducer);

  const dispatch = useDispatch();

  const checar_tipo_usuario_has_access = useCallback(
    (parameters) => {
      let permission = false;
      for (let i = 0; i < parameters.length; i++) {
        let tipo = parameters[i].split(":")[0];
        let tipo_dado = parameters[i].split(":")[1];
        if (
          reducer.user.tipo_usuario?.permissoes_notificacoes!=undefined &&
          reducer.user.tipo_usuario?.permissoes_notificacoes[tipo] !=
            undefined &&
          reducer.user.tipo_usuario?.permissoes_notificacoes[tipo][
            tipo_dado == undefined ? "has_access" : tipo_dado
          ] == true
        ) {
          permission = true;
        }
      }
      return permission;
    },
    [reducer.user]
  );

  useEffect(() => {
    let permissao = reducer.user?.modulos?.filter(
      (item) => item.nome == "NotificacaoIndividual"
    );

    if(permissao==undefined) permissao=[]

    console.log('permissao', permissao.length)

    let info = {
      reducer: reducer,
      org_name: NAME,
      private_notifications:
        permissao.length == 0 ? true : permissao[0].can_chose,
    };

    if (
      checar_tipo_usuario_has_access([
        "destinacaointerna.ativar_notificacoes:has_access",
      ])
    ) {
      fetch(`${URL}api/count_notifications`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${reducer.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          org_name: NAME,
          user_id: reducer.user.id,
        }),
      }).then(async (responseLog) => {
        let resp = await responseLog.json();
        dispatch(notificationCounter(resp.count));
      });

      let audio = new Audio(notificationSound);
      //* Websocket connection + realtime notifications wherever the user is
      let echo = wsConnection();
      notifications(echo, info, (e) => {
        dispatch(notificationCounter(e.count));
        audio.play();
      });
    }
  }, [reducer.user]);

  return (
    <div className="topbar hidethis">
      <nav
        className="navbar-custom d-flex justify-content-between align-items-center"
        style={{ marginLeft: marginLeft, transition: "all 0.3s " }}
      >
        <div
          className="btn-toggle"
          style={{ background: "#ececf1", color: "#353535" }}
          onClick={() => {
            if (window.innerWidth <= 768) {
              handleToggleSidebar(!toggled);
            } else {
              handleCollapsedChange(!collapsed);
            }
          }}
        >
          <FaBars />
        </div>
        <div
          className="d-lg-flex d-none p-2"
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: 15 }}
        >
          <div className="col-xs-6" style={{ display: "flex" }}>
            <b style={{ fontSize: 16 }}>Nome do usuário:&nbsp; </b>
            <p style={{ fontSize: 16 }}>
              {reducer.user.name}&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div className="col-xs-6" style={{ display: "flex" }}>
            <b style={{ fontSize: 16 }}>Tipo de usuário:&nbsp; </b>
            <p style={{ fontSize: 16 }}>
              {reducer.user.tipo_usuario.nome}&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
        </div>

        <ul className="navbar-right d-flex align-items-center list-inline float-right mb-0">
          {checar_tipo_usuario_has_access([
            "destinacaointerna.ativar_notificacoes:has_access",
          ]) && (
            <Link to={"/notificacoes"}>
              <IconButton size="small" color="inherit">
                <Badge
                  badgeContent={reducer.notifications}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                  color="error"
                >
                  <FaRegBell
                    style={{
                      color: "#212529",
                    }}
                  />
                </Badge>
              </IconButton>
            </Link>
          )}
          <ProfileMenu />
        </ul>
      </nav>
    </div>
  );
};

export default Header;
