import { URL, URLopen } from "../variables"

//---------------Get fetch function---------------
export async function GET({ url, blueenOpen }) {
  const token = localStorage.getItem('token')
  return (fetch(`${blueenOpen ? URLopen : URL}api/${url}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(async (response) => {
    const resp = await response.json()
    return resp
  })
  )
}

//---------------Post fetch function---------------
export async function POST({ url, body, blueenOpen }) {
  const token = localStorage.getItem('token')

  const headers = {
    Accept: 'application/json',
    ...(typeof body === 'string' && { 'Content-Type': 'application/json' }),
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  return (fetch(`${blueenOpen ? URLopen : URL}api/${url}`, {
    method: 'POST',
    headers,
    body
  }).then(async (response) => {
    if (!response) {
      return { error: 'Erro ao conectar com o servidor' }
    }

    if (!response.ok) {
      return getErrorMessages(response)
    }

    const resp = await response.json()
    return resp
  })
  )
}

//---------------Put fetch function---------------
export async function PUT({ url, body, blueenOpen }) {
  const token = localStorage.getItem('token')

  const headers = {
    Accept: 'application/json',
    ...(typeof body === 'string' && { 'Content-Type': 'application/json' }),
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  return (fetch(`${blueenOpen ? URLopen : URL}api/${url}`, {
    method: 'PUT',
    headers,
    body
  }).then(async (response) => {
    if (!response) {
      return { error: 'Erro ao conectar com o servidor' }
    }

    if (!response.ok) {
      return getErrorMessages(response)
    }

    const resp = await response.json()
    return resp
  })
  )
}

//---------------Patch fetch function---------------
export async function PATCH({ url, body, blueenOpen }) {
  const token = localStorage.getItem('token')

  const headers = {
    Accept: 'application/json',
    ...(typeof body === 'string' && { 'Content-Type': 'application/json' }),
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  return (fetch(`${blueenOpen ? URLopen : URL}api/${url}`, {
    method: 'PATCH',
    headers,
    body
  }).then(async (response) => {
    if (!response) {
      return { error: 'Erro ao conectar com o servidor' }
    }

    if (!response.ok) {
      return getErrorMessages(response)
    }

    const resp = await response.json()
    return resp
  })
  )
}

//---------------Delete fetch function---------------
export async function DELETE({ url, blueenOpen }) {
  const token = localStorage.getItem('token')
  return (fetch(`${blueenOpen ? URLopen : URL}api/${url}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(async (response) => {
    const resp = await response.json()
    return resp
  })
  )
}

const getErrorMessages = async (response) => {
  try {
    const resp = await response.json()
    const errors = Object.keys(resp.errors).map(item => resp.errors[item][0] + '\n')

    return { error: errors }
  } catch (error) {
    return { error: 'Erro ao conectar com o servidor' };
  }
}