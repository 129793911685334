
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';

// users
import { URL } from '../../../variables';

import { MdAdd, MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

class CriarRR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/residuos_rejeitos',
            loading_screen: false,
            classes: [],
            periculosidades: [],
            estados: [],
            acondicionamentos: [],
            destinacoes: [],
            armazenamentos: [],
            tratamentos: [],
            unidades: [],
            sub_materiais: [],

            submaterial: '',
            classe: '',
            periculosidade: '',
            estado: '',
            acondicionamento: '',
            destinacao: '',
            armazenamento: '',
            tratamento: '',
            unidade_peso: '',
            unidade_volume: '',
            tipo_medida_principal: '',
            tipo_movimentacao_financeira: '',
            valor_saldo: '',
            autorizacao: '',
            equivalencia: '',
            medida_principal: '',
            medida_secundaria: '',
            descricao: '',
            nome: '',
            sigla: '',
            tipo: '',
            loading_save: false,
            msgErro: ''
        };
    }


    get_data() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_data_residuos_rejeitos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false,
                        classes: resp.classes,
                        periculosidades: resp.periculosidades,
                        estados: resp.estados,
                        acondicionamentos: resp.acondicionamentos,
                        destinacoes: resp.destinacoes,
                        armazenamentos: resp.armazenamentos,
                        tratamentos: resp.tratamentos,
                        unidades: resp.unidades
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar() {
        let sub_materias = this.state.sub_materiais.filter((item) => item.nome != null);

        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/store_residuos_rejeitos`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                submateriais: sub_materias,
                tipo: this.state.tipo,
                classe_id: this.state.classe,
                periculosidade_id: this.state.periculosidade,
                estado_id: this.state.estado,
                acondicionamento_id: this.state.acondicionamento,
                destinacao_id: this.state.destinacao,
                armazenamento_id: this.state.armazenamento,
                tratamento_id: this.state.tratamento,
                descricao: this.state.descricao,
                unidade_principal_id: this.state.medida_principal,
                unidade_secundaria_id: this.state.medida_secundaria,
                equivalencia: this.state.equivalencia,
                autorizacao_movimentacao: this.state.autorizacao == 'Sim' ? true : (this.state.autorizacao == 'Não' ? false : ''),
                saldo_financeiro: this.state.tipo_movimentacao_financeira,
                valor_saldo_financeiro: this.state.valor_saldo,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_data();
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    filterUnits(filterOptions) {
        return this.state.unidades.filter(item => filterOptions.includes(item.tipo));
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar resíduo/rejeito</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os resíduos/rejeitos do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do resíduo/rejeito" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tipo: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um tipo</option>
                                                    <option value={'Resíduo'}>Resíduo</option>
                                                    <option value={'Rejeito'}>Rejeito</option>



                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Classe <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.classe} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ classe: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma classe</option>

                                                    {this.state.classes.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Periculosidade </label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.periculosidade} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ periculosidade: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma periculosidade</option>
                                                    {this.state.periculosidades.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Estado físico <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.estado} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ estado: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um estado físico</option>
                                                    {this.state.estados.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Acondicionamento <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.acondicionamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ acondicionamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um acondicionamento</option>
                                                    {this.state.acondicionamentos.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao} </option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destinação/Disposição <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.destinacao} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ destinacao: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma destinação</option>
                                                    {this.state.destinacoes.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Armazenamento temporário <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.armazenamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ armazenamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um armazenamento temporário</option>
                                                    {this.state.armazenamentos.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}


                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tratamento <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tratamento} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tratamento: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um tratamento</option>
                                                    {this.state.tratamentos.map((item, id) => (
                                                        <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Sub-material </label>
                                            <div className="input-group col-sm-12">
                                                <input type="text" className="form-control" onChange={(e) => this.setState({ submaterial: e.target.value })} value={this.state.submaterial} placeholder="Sub-material" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                <button className="btn btn-outline-success" type="button" id="button-addon2" onClick={() => {
                                                    if (this.state.submaterial != '') {
                                                        let sub_material = this.state.sub_materiais;
                                                        sub_material.push({ "nome": this.state.submaterial });
                                                        console.log(sub_material);
                                                        this.setState({
                                                            sub_materiais: sub_material,
                                                            submaterial: '',
                                                        });
                                                    }

                                                }} ><MdAdd /> </button>
                                            </div>
                                        </div>
                                        {this.state.sub_materiais.length != 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Sub materiais selecionados:</label>

                                                {this.state.sub_materiais.map((item, index) => (
                                                    <div key={index} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        border: '1px solid gray',
                                                        borderRadius: 10,
                                                        marginLeft: 10,
                                                        marginTop: 10
                                                    }}>
                                                        <span style={{ width: '120px' }} >{item.nome}</span>
                                                        <button onClick={() => {
                                                            let submateriais_selecionados = this.state.sub_materiais;
                                                            delete (submateriais_selecionados[index]);
                                                            this.setState({ sub_materiais: submateriais_selecionados });

                                                        }}
                                                            className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', marginTop: '4px', border: '0px solid red' }}><FaTrash color='red' /></button>
                                                    </div>
                                                ))}

                                            </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição do resíduo/rejeito" type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao}></textarea>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Tipo da medida principal <b style={{ color: 'red' }}>*</b></label>

                                                        <select value={this.state.tipo_medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ tipo_medida_principal: e.target.value, medida_principal: '', medida_secundaria: '' })
                                                        }}>
                                                            <option value={''}>Selecione um tipo para a medida principal</option>
                                                            <option value={'Peso'}>Peso</option>
                                                            <option value={'Volume'}>Volume</option>
                                                            <option value={'Unidade'}>Unidade</option>


                                                        </select>
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Medida principal <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ medida_principal: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione a medida principal</option>

                                                            {this.filterUnits([this.state.tipo_medida_principal]).map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}

                                                        </select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Em {this.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso'}, corresponde à (equivalência): <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            valueIsNumericString
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={3}
                                                            decimalSeparator=","
                                                            placeholder={"Equivalência em " + (this.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso')}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ equivalencia: values.value })
                                                            }}
                                                            value={this.state.equivalencia} />
                                                    </div>
                                                    <div className="col-md-6 col-12" style={{ alignSelf: 'flex-end' }}>
                                                        <label className="col-form-label">Medida secundária ({this.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso'}): <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.medida_secundaria} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ medida_secundaria: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione a medida secundária</option>

                                                            {this.filterUnits(this.state.tipo_medida_principal === 'Peso' ? ['Volume', 'Unidade'] : 'Peso').map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Requer autorização de movimentação? <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.autorizacao} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ autorizacao: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione</option>
                                                    <option value={'Sim'}>Sim</option>
                                                    <option value={'Não'}>Não</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo de movimentação financeira <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo_movimentacao_financeira} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tipo_movimentacao_financeira: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione o tipo de movimentação financeira</option>
                                                    <option value={'Receita'}>Receita</option>
                                                    <option value={'Despesa'}>Despesa</option>
                                                    <option value={'Despesa Evitada'}>Despesa Evitada</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Valor de saldo financeiro estimado <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <NumericFormat className="form-control"
                                                    placeholder="Valor de saldo financeiro estimado" type="text"
                                                    prefix={'R$ '}
                                                    thousandsGroupStyle='thousand'
                                                    thousandSeparator="."
                                                    decimalScale={2}
                                                    decimalSeparator=","
                                                    valueIsNumericString
                                                    onValueChange={(values, e) =>
                                                        this.setState({ valor_saldo: values.value })}
                                                    value={this.state.valor_saldo} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/residuos_rejeitos"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar resíduo/rejeito</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarRR);


