
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarDados } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { maskNumber } from '../../../Auxiliar/Masks';
import SweetAlert from 'react-bootstrap-sweetalert';

class SuperAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            page: 1,
            descricao: '',
            gravar: '',
            show_message: false,
            show_success: false,
            show_warning: false,
            permissoes: {
                'configuracao_geral': { acesso: false, nome: 'Configuração Geral' },
                'gestao_acesso': { acesso: false, nome: 'Gestão de acesso' },
                'personalizacao_residuos': { acesso: false, nome: 'Personalização Resíduo' },
                'personalizacao_veiculos': { acesso: false, nome: 'Personalização Veículo' },
                'cadastro': { acesso: false, nome: 'Cadastros' },
                'coleta_transporte': { acesso: false, nome: 'Coleta e transporte' },
                'triagem': { acesso: false, nome: 'Triagem' },
                'gestao_interna': { acesso: false, nome: 'Gestão interna' },
                'relatorios': { acesso: false, nome: 'Relatórios e Indicadores' },
                'comunicacao': { acesso: false, nome: 'Comunicação' },
                'app': { acesso: false, nome: 'Aplicativo' },
                'sigor': { acesso: false, nome: 'Sigor' },
                'blueen_open': { acesso: false, nome: 'Blueen Open' },
            },

            categorias: {},

            loading_save: false,
            msgErro: ''
        };
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    is_important(item, check) {
        console.log('item', item)
        if (item == 'configuracao_geral' && check == false || item == 'personalizacao_residuos' && check == false || item == 'personalizacao_veiculos' && check == false || item == 'gestao_acesso' && check == false || item == 'cadastro' && check == false) {
            this.setState({ show_message: true })
        } else {
            this.setState({ show_message: false })
        }
    }

    get_permissoes(page, search = '', busca = false) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_categorias_admin`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let permissoes = {};
                    for (let i = 0; i < Object.keys(this.state.permissoes).length; i++) {
                        permissoes[Object.keys(this.state.permissoes)[i]] = Object.values(this.state.permissoes)[i];
                    }

                    for (let i = 0; i < Object.keys(resp.categorias).length; i++) {
                        permissoes[Object.keys(resp.categorias)[i]] = Object.values(resp.categorias)[i];
                    }


                    this.setState({
                        permissoes: permissoes,
                        loading_screen: false
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    componentDidMount() {
        this.get_permissoes(this.state.page);
    }

    salvar() {
        this.setState({ loading_save: false, msgErro: 'Alterações devem ser feitas pelo painel SuperAdmin' });
        window.scrollTo(500, 0);
        return

        if (this.state.permissoes.relatorios.acesso == true && (
            this.state.permissoes.coleta_transporte.acesso == false &&
            this.state.permissoes.gestao_interna.acesso == false
        )) {
            this.setState({ loading_save: false, msgErro: `Para ativar os relatórios, ative o módulo 'Gestão Interna' ou 'Coleta e Transporte'` });
            window.scrollTo(500, 0);
            return
        }

        fetch(`${URL}api/update_categoria_admin`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                permissoes: this.state.permissoes,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.props.mudarDados({ permissoes: resp.permissoes });
                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Parâmetro de Acesso</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um parâmetro de acesso do seu sistema</p>
                            <br />

                        </div>
                        {this.state.loading_screen == false && <div className="row">
                            <div className="col-12">
                                {this.state.msgErro != "" && (
                                    <div className='container'>
                                        <div
                                            className="alert alert-danger alert-dismissible fade show mb-0"
                                            role="alert"
                                        >
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="alert"
                                                aria-label="Close"
                                                onClick={() => this.setState({ msgErro: "" })}
                                            ></button>

                                            <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                                                {this.state.msgErro}
                                            </p>
                                        </div>
                                        <br />
                                    </div>
                                )}
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <div className="row " style={{ marginLeft: 20, marginRight: 20 }}>
                                            {Object.keys(this.state.permissoes).map((item, id) => (
                                                <div key={id} className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ marginLeft: -10 }}>{this.state.permissoes[item].nome}</label>
                                                    <div className="col-sm-12">
                                                        <div className="row " style={{ marginLeft: -10, marginRight: 0 }}>
                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">
                                                                <input disabled className="form-check-input" type="checkbox" id={'Acesso-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].acesso} onChange={(e) => {
                                                                    <label className="form-check-label" htmlFor={'Acesso -' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].acesso}>Acesso</label>
                                                                    let permissoes = { ...this.state.permissoes };
                                                                    // console.log(this.is_important(item, e.target.checked));

                                                                    permissoes[item].acesso = e.target.checked;
                                                                    this.setState({ permissoes });

                                                                    if (item == 'coleta_transporte' || item == 'gestao_interna' || item == 'relatorios') {
                                                                        if (permissoes.coleta_transporte.acesso == false && permissoes.gestao_interna.acesso == false) {
                                                                            permissoes['relatorios'].acesso = false;
                                                                        }
                                                                    }
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))}

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.state.loading_save == false && <div className="col-12  d-flex justify-content-center justify-content-sm-end"
                                style={{ marginBottom: '0.5rem', paddingRight: 25, paddingLeft: 25 }}>

                                {this.state.show_message ?
                                    <button type="button" onClick={() => {
                                        this.setState({ show_warning: true });
                                    }} className="btn btn-success btn-lg waves-effect waves-light"
                                        style={{ backgorundColor: '#f2f2f2' }}>Salvar dados</button> :

                                    <button type="button" onClick={() => {
                                        this.setState(this.salvar());
                                    }} className="btn btn-success btn-lg waves-effect waves-light"
                                        style={{ backgorundColor: '#f2f2f2' }}>Salvar dados</button>}
                            </div>}
                            {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem', marginBottom: '0.5rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                        </div>}

                        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem', marginBottom: 20 }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>}
                    </div>

                </div>

                <SweetAlert
                    success
                    title={"Sucesso"}
                    onConfirm={() => {
                        this.setState({ show_success: false })
                    }}

                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    confirmBtnBsStyle="success"
                    showCancel={false}
                >
                    Dados salvos com sucesso

                </SweetAlert>
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.salvar();
                        this.setState({ show_warning: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo desativar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Modulo importante, você realmente deseja desativá-lo?
                </SweetAlert>
            </div>

        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);



export default connect(mapsStateToProps, { logout, mudarDados })(SuperAdmin);


