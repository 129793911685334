import React, { Component } from 'react';
import { MdReply } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { URL } from '../../../variables';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class Atualizacoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            dados: [
                { tipo: 'classes', nome: 'Classes', url: 'retornaListaClasse', atualizacao: false },
                { tipo: 'unidades', nome: 'Unidades', url: 'retornaListaUnidade', atualizacao: false },
                { tipo: 'tratamentos', nome: 'Tratamentos', url: 'retornaListaTratamento', atualizacao: false },
                { tipo: 'estados', nome: 'Estados Físicos', url: 'retornaListaEstadoFisico', atualizacao: false },
                { tipo: 'acondicionamentos', nome: 'Acondicionamentos', url: 'retornaListaAcondicionamento', atualizacao: false },
                { tipo: 'residuos', nome: 'Resíduos', url: 'retornaListaResiduo', atualizacao: false }
            ],
            selecionados: [],
            dados_sigor: {
                'classes': {},
                'unidades': {},
                'tratamentos': {},
                'estados': {},
                'acondicionamentos': {},
                'residuos': {}
            },
            msgErro: '',
            token_sigor: '',
            show_warning: false,
            show_erro: false,
            tipo_to_delete: '',
            search: '',
            loading_search: false
        };
    }

    componentDidMount() {
        this.get_token_sigor();
    }

    get_token_sigor() {
        this.setState({ loading_screen: true, msgErro: '' });
        fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/gettoken`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpfCnpj: '31977663850',
                senha: 'Verdaz123',
                unidade: '59988'
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.erro == true) {
                    let erro = resp.mensagem
                    let erros = [
                        'Sucesso',
                        'Problema na autenticação!',
                        'Cpf/Cnpj não foi cadastrado em dados da empresa!',
                        'Senha não foi cadastrada em dados da empresa!',
                        'Unidade não foi cadastrada em dados da  empresa!',
                        'Cpf/Cnpj cadastrado em dados da empresa não é valido!',
                        'Unidade deve conter apenas números!',
                        'Autenticação inválida!'
                    ]
                    erros.map((item, index) => {
                        if (erro.includes('00' + index)) {
                            console.log(index)
                            erro = item;
                        }
                    })
                    console.log(erro);
                    this.setState({ loading_screen: false, msgErro: erro, loading_save: false });
                }
                else {
                    this.setState({
                        loading_screen: false,
                        loading_save: false,
                        token_sigor: resp.objetoResposta
                    })
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_screen: false, msgErro: 'Erro ao pegar resposta do servidor', loading_save: false });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_screen: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', loading_save: false });
            });
    }

    get_data(tipo, url, array_length, count) {
        console.log('path', this.props)
        console.log(this.state.token_sigor);
        console.log(url);
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/${url}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': this.state.token_sigor
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.mensagem != null) {
                    let mensagem = Object.values(resp.mensagem);
                    let erro = '';
                    for (let i = 0; i < mensagem.length; i++) {
                        if (i != mensagem.length - 1)
                            erro += mensagem[i] + '\n';
                        else
                            erro += mensagem[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let dados_sigor = this.state.dados_sigor;
                    dados_sigor[tipo] = resp.objetoResposta
                    console.log('testeeee', resp.objetoResposta, dados_sigor)
                    this.setState({
                        dados_sigor
                    }, () => {
                        if (count == array_length) {
                            { console.log("chegou aqui") }
                            this.atualiza();
                        }
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    salvar() {
        let dado_selecionado = this.state.dados.filter((item) => item.atualizacao == true);
        let contador = 0
        dado_selecionado.map((item) => {
            contador = contador + 1;
            this.get_data(item.tipo, item.url, dado_selecionado.length, contador);
        })
    }

    atualiza() {
        let dado_selecionado = this.state.dados.filter((item) => item.atualizacao == true);


        console.log(this.state.dados_sigor)
        dado_selecionado.map((item) => {
            if (this.state.dados_sigor[item.tipo].length > 0) {
                toast.loading('atualizando ' + item.nome, {
                    toastId: 'toastpromisse' + item.tipo,
                    hideProgressBar: false
                })
            }

        })

        this.setState({ msgErro: '' });
        console.log(this.state.dados_sigor)
        fetch(`${URL}api/update_sigor`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({

                ...this.state.dados_sigor,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                    for (let i = 0; i < dado_selecionado.length; i++) {
                        toast.update('toastpromisse' + dado_selecionado[i].tipo, {
                            render: "Falhou",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    }
                }
                else {
                    for (let i = 0; i < dado_selecionado.length; i++) {
                        if ((i + 1) == dado_selecionado.length) {
                            console.log()
                            toast.update('toastpromisse' + dado_selecionado[i].tipo, {
                                render: "Sucesso",
                                type: "success",
                                isLoading: false,
                                autoClose: 500,
                                onClose: setTimeout(() => { this.setState({ redirect: true }) }, 500)
                            });
                            this.setState({ loading_save: false })
                        } else {
                            toast.update('toastpromisse' + dado_selecionado[i].tipo, {
                                render: "Sucesso",
                                type: "success",
                                isLoading: false,
                                autoClose: 500,
                            });
                        }
                    }

                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
                for (let i = 0; i < dado_selecionado.length; i++) {
                    toast.update('toastpromisse' + dado_selecionado[i].tipo, {
                        render: "Falhou",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                    });
                }
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                for (let i = 0; i < dado_selecionado.length; i++) {
                    toast.update('toastpromisse' + dado_selecionado[i].tipo, {
                        render: "Falhou",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                    });
                }
            })
    }

    reload() {
        window.location.reload(true);
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && this.reload()}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Atualizações Sigor</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Atualize os dados da Sigor do seu sistema</p>
                            <br />

                            {this.state.loading_screen == false && <div>

                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                                    </div>
                                    <br />
                                </div>}

                                <div className="row">
                                    <div className="col-12">
                                        {this.state.dados.map((item, index) => (<div key={item.tipo} className="form-group row">
                                            <label className="col-sm-12 col-form-label">{item.nome}</label>
                                            <div className="col-sm-12">
                                                <div className="row " style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div className="form-check form-switch col-12 col-md-4 col-lg-4">
                                                        <input className="form-check-input" type="checkbox" id={item.nome} checked={item.atualizacao} onChange={(e) => {
                                                            let dados = this.state.dados;
                                                            dados[index].atualizacao = e.target.checked;

                                                            if (e.target.checked == false) {
                                                                let dados_sigor = this.state.dados_sigor;
                                                                dados_sigor[item.tipo] = {}
                                                                this.setState({ dados_sigor, dados });
                                                            } else {
                                                                this.setState({ dados })
                                                            }
                                                        }} />
                                                        <label className="form-check-label" htmlFor={item.nome} checked={item.atualizacao}>atualizar</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/tipos_usuario"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Atualizar dados</button>
                                    </div>
                                </div>}

                                {this.state.loading_save == true &&
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div>
                                    </div>}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <ToastContainer
                                position="bottom-right"
                                toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={true}
                                pauseOnHover
                            />

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Atualizacoes);
