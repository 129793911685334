
import React, { Component } from 'react';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

// solicitacoes

import Despachos from './DespachosSemPesar';
import DespachosPesados from './DespachosPesados';
import Filter from '../../../Auxiliar/Filter';

const SelecionarJsPesagem = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        despacho: { value: true, label: 'Despachos Pendentes', checked: true },
        despachoPendente: { value: false, label: 'Despachos Pesados', checked: false },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Pesagem</h4>
                    <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados as pesagens do sistema</p>
                </div>
                <div className="row">
                    {options.despacho.value &&
                        <Despachos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.despachoPendente.value &&
                        <DespachosPesados dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsPesagem